import React, { useState } from "react"
import { useDispatch } from "react-redux"

import RoleSelection from "../register/components/RoleSelection"

import { openNotification } from "../../util/openNotifications"

import useRouterNavigation from "../../hooks/useNavigate"

import { useAssignRoleMutation } from "../../api/apiSlices/authApiSlice"
import { Routes } from "../../constants/routes"
import { setUser } from "../../api/auth"
import { setUserRole } from "../../redux/slices/auth"
import { ROLES } from "../../constants/roles"

const SelectPlatform = () => {
  const [mainRole, setMainRole] = useState()

  const { navigateTo } = useRouterNavigation()

  const dispatch = useDispatch()

  const [
    assignRole,
    { isLoading: isAssignRoleLoading, error: assignRoleError }
  ] = useAssignRoleMutation()

  const redirectTo = role => {
    switch (role) {
      case ROLES.SOCIAL_USER:
        navigateTo(Routes.HOME)
        break
      case ROLES.EMPLOYER:
        navigateTo(Routes.DASHBOARD)
        break
      case ROLES.WORKER:
        navigateTo(Routes.USER_EXPERIENCE)
        break
      case ROLES.DONOR:
        navigateTo(Routes.DONATION)
        break
      default:
        break
    }
  }

  const onChangeMainRole = async role => {
    if (role === "flex-time") {
      setMainRole(role)
    } else {
      try {
        const response = await assignRole({ role: role }).unwrap()
        dispatch(setUserRole({ role: response?.role }))
        setUser(response?.user)
        redirectTo(response?.role)
        openNotification({
          type: "success",
          message: "Switched Platform successfully"
        })
      } catch (error) {
        openNotification({
          type: "error",
          message:
            error?.message || "Failed to switch platform please try again!"
        })
      }
    }
  }

  return (
    <RoleSelection mainRole={mainRole} onChangeMainRole={onChangeMainRole} />
  )
}

export default SelectPlatform

import React, { useState } from "react"
import { Typography } from "antd"
import "./styles.scss"

const { Text } = Typography

const SeeMoreText = ({ content, maxLength = 100 }) => {
  const [expanded, setExpanded] = useState(false)

  // Determine if we need the "see more" button
  const isLongText = content.length > maxLength

  // Display either the full content or the truncated content
  const displayedText = expanded ? content : content.substring(0, maxLength)

  return (
    <div>
      <Text className="text">
        {displayedText}
        {isLongText && !expanded && "..."}
      </Text>
      {isLongText && (
        <Text className="seeMoreOption" onClick={() => setExpanded(!expanded)}>
          {expanded ? "See Less" : "More"}
        </Text>
      )}
    </div>
  )
}

export default SeeMoreText

import React from 'react';
import Header from './Header';
import { Form, Button, Input, DatePicker, Select, Row, Col } from 'antd';
import { Typography } from 'antd';
import DayJs from "dayjs"
import { jobDetails } from '../../../schemas/JobCreationSchema';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Text } = Typography;

function JobDetails({
  onNextClick,
  onPreviousClick,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues
}) {
  const DESCRIPTION = "Provide below mentioned details to help candidates understand the job.";
  const DESCRIP = "Enter your hourly rate (USD) between $20 and $150 to align with platform standards";

  const countries = ['United States', 'Canada', 'Germany', 'India', 'Australia'];
//console.log("values",values)
  return (
    <>
      <div>
        <Header title="Job Details" description={DESCRIPTION} />

        <Form layout="vertical">
          <Title level={5}>Hourly Rate</Title>
          
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="From"
                validateStatus={touched?.rateMin && errors?.rateMin ? 'error' : ''}
                help={touched?.rateMin && errors?.rateMin}
              >
                <Input
                  type="number"
                  name="rateMin"
                  value={values?.rateMin || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  suffix="/hr"
                  step="0.1"
                />
                {/* {touched["rateMin"] && errors?.rateMin
                  ? "error"
                  : undefined} */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="To"
                validateStatus={touched?.rateMax && errors?.rateMax ? 'error' : ''}
                help={touched?.rateMax && errors?.rateMax}
              >
                <Input
                  type="number"
                  name="rateMax"
                  value={values?.rateMax || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  suffix="/hr"
                  step="0.1"
                />
              </Form.Item>
            </Col>
          </Row>
          
        
           <Text>{DESCRIP}</Text> 
        

         <div style={{ marginTop: '16px'}}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Number of Workers Needed"
                validateStatus={touched?.workersNeeded && errors?.workersNeeded ? 'error' : ''}
                help={touched?.workersNeeded && errors?.workersNeeded}
              >
                <Input
                  type="number"
                  name="workersNeeded"
                  value={values?.workersNeeded||''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* {errors?.jobDetails?.workersNeeded&&<div>{errors?.jobDetails.workersNeeded}</div>} */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Job Duration"
                validateStatus={touched?.dateRange && errors?.dateRange ? 'error' : ''}
                help={touched?.dateRange && errors?.dateRange}
              >
            


<RangePicker
  name="dateRange"
  // value={[values?.dateRange[0] ? DayJs(values?.dateRange[0]) : null , values?.dateRange[1] ?  DayJs(values?.dateRange[1]) : null]}
  value={
    values?.dateRange?.length === 2
      ? [DayJs(values.dateRange[0]), DayJs(values.dateRange[1])]
      : [null, null]
  }
  disabledDate={(current) => current && current < DayJs().endOf('day')}
  onChange={(_, dates) => {
    console.log("data",dates)
    //setValues({...values,jobDetails:{...values.jobDetails,dateRange:dates}});
    setValues({ ...values, dateRange: dates });

    // setValues({...values , })
    // setValues({ dates});
    // if (dates && dates.length === 2) {
    //   // Store the selected dates as strings
    //   setFieldValue('jobDetails.dateRange', [dates[0], dates[1]]);
    //   setValues({...values,jobDetails:{...values.jobDetails,dateRange:[dates[0],dates[1]]}});
    // } else {
    //   setValues({...values,jobDetails:{...values.jobDetails,dateRange:[]}});
    // }
  }}
  // onBlur={handleBlur}
/>

               
              </Form.Item>
            </Col>
          </Row>
          </div>
          <Form.Item
            label="Location"
            validateStatus={touched?.location && errors?.location ? 'error' : ''}
            help={touched?.location && errors?.location}
          >
            <Select
              name="location"
              value={values?.location||''}
              onChange={(value) => setFieldValue('location', value)}
              placeholder="Select location"
            >
              {countries.map((country) => (
                <Select.Option key={country} value={country}>
                  {country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Additional Notes"
            validateStatus={touched?.notes && errors?.notes ? 'error' : ''}
            help={touched?.notes && errors?.notes}
          >
            <Input.TextArea
              name="notes"
              value={values?.notes||''}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            />
          </Form.Item>

          <Row justify="end" gutter={16}>
            <Col>
              <Button type="default" onClick={onPreviousClick}>
                Back
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onNextClick}>
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default JobDetails;

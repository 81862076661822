import * as Yup from "yup"

export const registerValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  username: Yup.string()
    .required("Username is required")
    .matches(
      /^[\w.@+-]+$/,
      "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
    ),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .matches(/[A-Z]/, "Must contain an uppercase letter")
    .matches(/[a-z]/, "Must contain a lowercase letter")
    .matches(/\d/, "Must contain a number")
    .required("Password is required")
})

import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Button, Modal, Spin, Typography } from "antd"
import {
  useAcceptPrivacyPolicyChangesMutation,
  useFetchPrivacyPolicyQuery
} from "../../api/apiSlices/settingsApiSlice"

export default function PrivacyPolicyUpdated({ refetchPrivacyPolicyStatus }) {
  const { data: privacyPolicy, isLoading } = useFetchPrivacyPolicyQuery()
  const [acceptPrivacyPolicyChanges] = useAcceptPrivacyPolicyChangesMutation()

  const onAcceptPrivacyPolicyChanges = () => {
    acceptPrivacyPolicyChanges().then(() => {
      refetchPrivacyPolicyStatus()
    })
  }
  return (
    <Modal
      title="Privacy Policy Updated"
      icon={<ExclamationCircleOutlined />}
      centered
      width={700}
      open
      closable={false}
      footer={
        <div>
          <Button type="primary" onClick={onAcceptPrivacyPolicyChanges}>
            Accept Changes
          </Button>
        </div>
      }
    >
      <div>
        <Spin spinning={isLoading}>
          <Typography.Paragraph strong>
            We want to inform you that we have made the following changes to our
            Privacy Policy, Please accept these changes to continue using our
            services.
          </Typography.Paragraph>
          <Typography.Paragraph>
            {!isLoading && privacyPolicy.recent_changes}
          </Typography.Paragraph>
        </Spin>
      </div>
    </Modal>
  )
}

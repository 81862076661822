import React, { useState } from "react"
import { Avatar, Button, Flex, Modal, Space, Typography } from "antd"

import CustomModal from "../../../../components/Modal/CustomModal"
import { ChatIcon } from "../../../../assets/rawSvgs"
import CustomInput from "../../../../components/customInput/CustomInput"

import {
  useDeleteUserConnectionMutation,
  useGetUserConnectionsQuery
} from "../../../../api/apiSlices/socialMediaApiSlice"

import { useDebounce } from "../../../../hooks/useDebounce"

import {
  ExclamationCircleOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons"

const { Text } = Typography

const UserConnectionManagment = ({ friendsCount = 0 }) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")

  const debouncedSearchTerm = useDebounce(search, 500)

  const [modal, contextHolder] = Modal.useModal()

  const {
    data: connections,
    isLoading: isConnectionsLoading,
    refetch: refetchConnections
  } = useGetUserConnectionsQuery(debouncedSearchTerm)

  const [
    deleteUserConnection,
    { isLoading: isDeleteConnectionLoading, error: deleteConnectionError }
  ] = useDeleteUserConnectionMutation()

  const showModal = () => {
    if (friendsCount === 0) return
    setOpen(true)
  }

  const handleDeleteConnection = async id => {
    await deleteUserConnection(id)
    refetchConnections()
  }

  const showRemoveModal = id => {
    modal.confirm({
      title: "Remove Friend",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to remove this account? This action cannot be undone.",
      okText: "Remove",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () => handleDeleteConnection(id),
      centered: true
    })
  }
  return (
    <>
      <Modal
        open={open}
        centered
        title="Friends"
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div className="feedbackModal">
          <Flex vertical gap="middle">
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {connections?.map((connection, index) => {
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex gap="small">
                    <Avatar size="small" icon={<UserOutlined />} />
                    <Text>
                      {connection?.connected_user?.username || "Anonymous"}
                    </Text>
                  </Flex>
                  <Flex gap="small">
                    <Button icon={<ChatIcon />} size="medium" />
                    <Button
                      onClick={() => showRemoveModal(connection?.connection_id)}
                    >
                      Remove
                    </Button>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </div>
      </Modal>
      <Text onClick={showModal} underline className="text-lg-500">
        {friendsCount} Friends
      </Text>
      {contextHolder}
    </>
  )
}

export default UserConnectionManagment

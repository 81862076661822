import React from "react"
import { Col, Empty, Flex ,Spin} from "antd"

import PostCard from "./components/postCard/PostCard"
import SuggestionsList from "./components/suggestionsList/suggestionsList"

import {
  useAddCommentToPostMutation,
  useGetSocialFeedQuery,
  useTogglePostLikeMutation
} from "../../api/apiSlices/socialMediaApiSlice"
import CommunitiesSuggestions from "../../components/communities-manage-banner/CommunitiesSuggestion"

const SocialMedia = () => {
  const { data: socialFeed , isLoading} = useGetSocialFeedQuery()

  const [togglePostLike] = useTogglePostLikeMutation()

  const [addCommentToPost, { isLoading: isAddCommentToPostLoading }] =
    useAddCommentToPostMutation()

  return (
    <div className="socialMediaScreen">
      <Spin spinning={isLoading}>
      <Flex gap="48px">
        <Col className="gutter-row" span={16}>
          <div className="socialMediaScroll">
            {socialFeed?.results?.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                vertical
                style={{ height: "100%" }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            ) : (
              socialFeed?.results?.map(feed => {
                return (
                  <PostCard
                    index={feed?.post_id}
                    attachments={feed?.attachments}
                    author={feed?.author}
                    comments={feed?.comments}
                    comments_count={feed?.comments_count}
                    comments_enabled={feed?.comments_enabled}
                    content={feed?.content}
                    created_at={feed?.created_at}
                    likes_count={feed?.likes_count}
                    post_id={feed?.post_id}
                    likes_enabled={feed?.likes_enabled}
                    user_has_liked={feed?.user_has_liked}
                    likes={feed?.likes}
                    togglePostLike={togglePostLike}
                    addCommentToPost={addCommentToPost}
                    isAddCommentToPostLoading={isAddCommentToPostLoading}
                    feed={feed}
                    key={feed?.post_id}
                  />
                )
              })
            )}
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <SuggestionsList />
          <CommunitiesSuggestions />
        </Col>
      </Flex>
      </Spin>
    </div>
  )
}

export default SocialMedia

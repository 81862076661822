import { PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Flex, List, TimePicker } from "antd"
import { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useAddCustomAvailabilityMutation } from "../../../api/apiSlices/workerApiSlice"
import { openNotification } from "../../../util/openNotifications"
import "./styles.scss"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

const convertUTCToLocal = utcTime => dayjs.utc(utcTime).local()
const convertLocalToUTC = localTime => localTime.utc().format()

function isTimeBefore(startTime, endTime) {
  if (!startTime || !endTime) return false

  const startHours = dayjs(startTime).hour()
  const startMinutes = dayjs(endTime).minute()

  const endHours = dayjs(endTime).hour()
  const endMinutes = dayjs(endTime).minute()
  if (startHours < endHours) return true
  if (startHours === endHours && startMinutes < endMinutes) return true

  return false
}

export const validationSchema = Yup.object({
  date: Yup.date().required("Start date is required"),
  start_time: Yup.string().required("Start time is required"),
  end_time: Yup.string()
    .required("End time is required")
    .when("start_time", (startTime, schema) => {
      if (startTime) {
        return schema.test(
          "is-after",
          "End time must be after start time",
          function (endTime) {
            return isTimeBefore(startTime, endTime)
          }
        )
      }
      return schema
    })
})

const getCombinedDateTime = (selectedDate, selectedTime) => {
  const combinedDateTime = selectedDate
    .hour(selectedTime.hour())
    .minute(selectedTime.minute())
    .second(selectedTime.second())
  return combinedDateTime
}

export default function CustomAvailabilityForm({ data, refetch }) {
  const [showCustomAvailabilityInput, setShowCustomAvailabilityInput] =
    useState(false)
  const [addCustomAvailability] = useAddCustomAvailabilityMutation()

  const { values, errors, handleSubmit, setValues, touched, resetForm } =
    useFormik({
      initialValues: {
        date: "",
        start_time: "",
        end_time: ""
      },
      validationSchema: validationSchema,
      onSubmit: values => {
        const data = {
          start_time: getCombinedDateTime(values.date, values.start_time),
          end_time: getCombinedDateTime(values.date, values.end_time)
        }
        addCustomAvailability(data)
          .unwrap()
          .then(() => {
            setShowCustomAvailabilityInput(false)
            refetch()
            resetForm()
          })
          .catch(error => {
            openNotification({ type: "error", message: error?.message })
          })
      }
    })

  const addNewCustomAvailablity = () => {
    if (showCustomAvailabilityInput) {
      return
    }
    setShowCustomAvailabilityInput(true)
  }

  return (
    <Flex wrap gap="10px" justify="space-between" className="mt-3">
      <div className="addWorkExp" onClick={() => addNewCustomAvailablity()}>
        <PlusOutlined />
        <h5>Add another </h5>
      </div>
      {showCustomAvailabilityInput && (
        <Flex align="flex-start" gap="8px">
          <Flex vertical>
            <label className={`Input_label`}>Date</label>
            <DatePicker
              allowClear={false}
              name="date"
              onChange={dateString =>
                setValues({ ...values, date: dateString })
              }
              value={values.date ? values.date : null}
              status={touched["date"] && errors?.date ? "error" : ""}
            />
            {touched["date"] && errors?.date && (
              <p className="Input_errorMessage">{errors?.date}</p>
            )}
          </Flex>
          <Flex vertical>
            <label className={`Input_label`}>Start time</label>
            <TimePicker
              allowClear={false}
              name="start_time"
              value={values.start_time ? values.start_time : null}
              onChange={time => {
                setValues({ ...values, start_time: time })
              }}
              status={
                touched["start_time"] && errors?.start_time ? "error" : ""
              }
              format="h:mm a"
            />
            {touched["start_time"] && errors?.start_time && (
              <p className="Input_errorMessage">{errors?.start_time}</p>
            )}
          </Flex>
          <Flex vertical>
            <label className={`Input_label`}>End time</label>
            <TimePicker
              allowClear={false}
              name="end_time"
              value={values.end_time ? values.end_time : null}
              onChange={time => {
                setValues({ ...values, end_time: time })
              }}
              status={touched["end_time"] && errors?.end_time ? "error" : ""}
              format="h:mm a"
            />
            {touched["end_time"] && errors?.end_time && (
              <p className="Input_errorMessage">{errors?.end_time}</p>
            )}
          </Flex>
          <Button className="mt-4" type="default" onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      )}
      <List
        style={{ width: "100%" }}
        size="large"
        dataSource={data?.custom_availability}
        renderItem={availablity => (
          <List.Item>
            <Flex gap="8px">
              <div>{dayjs(availablity.start_time).format("DD/MM/YYYY")}</div>
              <div>
                {dayjs(availablity.start_time).format("h:mm A")}
                {" - "}
                {dayjs(availablity.end_time).format("h:mm A")}
              </div>
            </Flex>
          </List.Item>
        )}
      />
    </Flex>
  )
}

import { useNavigate } from "react-router-dom";

const useRouterNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return {
    navigateTo,
  };
};

export default useRouterNavigation;

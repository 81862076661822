import React from 'react'
import Header from './components/Header'
import ViewSaved from './components/ViewSaved'
import ToneSelector from './components/ToneSelector'
import SearchBar from './components/InputBar'
import ContentGenerated from './components/ContentGenerated'
import { SaveIcon } from '../../../../assets/rawSvgs'
import { Space ,Layout,Divider} from 'antd'
import { useState } from 'react'
import { Typography } from 'antd';

const { Title} = Typography;

function PopoverContent({closePopover , handleInsertion}) {

    const [isSearching , setIsSearching]=useState(false);
    const[searchValue ,setSearchValue]=useState('');
    const [results, setResults] = useState([]); 
    const [saveItems , setSaveItems]=useState([]);
    const [showSavedItems, setShowSavedItems] = useState(false);
    const [toneSelector , setToneSelector]=useState(false);
    let [status , setStatus]=useState(true)
    
    
    const toggleSavedItemsView = () => {
        setShowSavedItems((prevState) => !prevState); 
    };

    const handleSearch = async (searchValue)=>
    
        {
      
        setSearchValue(searchValue)
        setIsSearching(true);

    try {
       
        const response = await fetch(`https://rickandmortyapi.com/api/character/?name=${searchValue}`);
        const data = await response.json();
        if(data.results && Array.isArray(data.results)){
        setResults((prevResults) => [...prevResults, ...data.results]); }
        else{
            console.error("no results founddd ");
         }

     
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
     
        setIsSearching(false); 
        setToneSelector(true)
      }


        
    }

    
     const handleSave = (item)=> {
      
        setSaveItems((prevSaveItems) => [...prevSaveItems, item]);
        
        
     };
     
   

  return (

<div>
<Header closePopover={closePopover} />
<Divider style={{ margin: 0, padding: 0 }}/>
<Layout style={LayoutContainer}>
    {showSavedItems ? (
       
        <ViewSaved saveItems={saveItems} closePopover={closePopover} toggleSavedItemsView={toggleSavedItemsView}/>
        
    ) : (
       
        <>
            {status && results.length > 0 ? (
                <>
                    <div style={ContentDisplay}>
                        {results.map((result, index) => {
                            const { name, id } = result;
                            return (<>
                                    <ContentGenerated result={name} key={id} onSave={() => handleSave(name)} handleInsertion={handleInsertion}/>
                                     <Divider/>
                                     </>);
                                     
                        })}
                    </div>
                    {isSearching && <span>Working.......</span>}
                    <ToneSelector backgroundColor='rgba(245, 245, 245, 1)'   />
                </>
            ) : (
                <div >
                    <div>
                    <Title level={5} >
                        What do you want to say?
                    </Title>
                    <div onClick={toggleSavedItemsView} style={{ marginTop: '20px' }}>
                        <Space direction="horizontal" size="small">
                            <SaveIcon />
                            <span>View Saved</span>
                        </Space>
                    </div>
                    </div>
                    <Divider/>
                    <div style={{marginTop:'20px'}}>
                    <ToneSelector />
                    </div>
                   
                </div>
            )}
            <SearchBar onSearch={handleSearch} />
        </>
    )}
</Layout>
</div>
);
}

const LayoutContainer ={
    width: '406px',
    paddingTop: '16px',
    paddingBottom:'16px',
    paddingRight:'24px',
    paddingLeft:'24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.02)'

}


const ContentDisplay ={
    maxHeight: '30vh',
    overflowY: 'scroll',
    scrollbarWidth:'none',
    maxWidth: '44vw',
    margin: '0 auto',
    padding: '10px',
    boxSizing: 'border-box',

}

export default PopoverContent



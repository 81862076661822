import { apiSlice } from "../apiSlice"

import {
  CREATE_SOCIAL_MEDIA_POST,
  ADD_USER_CONNECTIONS,
  DELETE_USER_CONNECTION,
  GET_SOCIAL_FEED,
  GET_USER_CONNECTIONS,
  GET_USER_SUGGESTIONS,
  TOGGLE_POST_LIKE,
  ADD_POST_COMMENT,
  GET_USER_POSTS,
  GET_SOCIAL_MEDIA_PROFILE,
  UPDATE_PROFILE,
  ACCEPT_CONNECTION_REQUEST,
  REJECT_CONNECTION_REQUEST,
  SOCIAL_USER_CHAT
} from "../../constants/endpoints"
import { getUser } from "../auth"

//// Dispatch an action to invalidate the cache for the social feed
//dispatch(api.util.invalidateTags(['social_feed']));

export const socialMedaiApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSocialFeed: builder.query({
      query: () => ({
        url: GET_SOCIAL_FEED
      }),
      keepUnusedDataFor: 0 
      //providesTags: ["social_feed"]
    }),
    getUserConnections: builder.query({
      query: (searchTerm, open = true) => ({
        url: GET_USER_CONNECTIONS.concat(`?search=${searchTerm}`)
      })
    }),
    getSuggestionlist: builder.query({
      query: () => ({
        url: GET_USER_SUGGESTIONS
      })
    }),
    deleteUserConnection: builder.mutation({
      query: id => ({
        url: DELETE_USER_CONNECTION.replace(":id", id),
        method: "DELETE"
      })
    }),
    addUserConnection: builder.mutation({
      query: body => ({
        url: ADD_USER_CONNECTIONS,
        method: "POST",
        body: body
      })
    }),
    createPost: builder.mutation({
      query: body => ({
        url: CREATE_SOCIAL_MEDIA_POST,
        method: "POST",
        body: body
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        let patchResult = null
        try {
          const { data } = await queryFulfilled
          const endPoint = data.community
            ? "getCommunitySocialFeed"
            : "getSocialFeed"

          const cacheKey = data.community ? String(data.community) : undefined

          patchResult = dispatch(
            apiSlice.util.updateQueryData(endPoint, cacheKey, draft => {
              draft.results.unshift(data)
            })
          )
        } catch (error) {
          if (patchResult) {
            patchResult.undo()
          }
        }
      }
    }),
    togglePostLike: builder.mutation({
      query: id => ({
        url: TOGGLE_POST_LIKE.replace(":postId", id),
        method: "POST"
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const currentUser = getUser()

        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getSocialFeed", undefined, draft => {
            const post = draft.results.find(post => post.post_id === id)
            if (post) {
              if (post.user_has_liked) {
                post.likes_count -= 1
                post.likes = post.likes.filter(
                  like => like.user_id !== currentUser.id
                )
              } else {
                post.likes_count += 1
                const newLike = {
                  user_id: currentUser.id,
                  username: currentUser.name,
                  name: currentUser.name,
                  profile_picture: null
                }
                post.likes.push(newLike)
              }
              post.user_has_liked = !post.user_has_liked
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    addCommentToPost: builder.mutation({
      query: ({ postId, content }) => ({
        url: ADD_POST_COMMENT.replace(":postId", postId),
        method: "POST",
        body: { content }
      }),
      async onQueryStarted({ postId, content }, { dispatch, queryFulfilled }) {
        const currentUser = getUser() // Assume you have the current user in state

        // Optimistically update the post's comments
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getSocialFeed", undefined, draft => {
            const post = draft.results.find(post => post.post_id === postId)
            if (post) {
              post.comments.push({
                comment_id: Date.now(), // Temporary ID
                author: {
                  user_id: currentUser.id,
                  username: null,
                  name: currentUser.name,
                  profile_picture: null
                },
                content: content,
                created_at: new Date().toISOString()
              })
              post.comments_count += 1
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getUserPosts: builder.query({
      query: () => ({
        url: GET_USER_POSTS
      })
    }),
    getSocialMediaProfile: builder.query({
      query: id => ({
        url: GET_SOCIAL_MEDIA_PROFILE.replace(":id", id)
      })
    }),
    updateSocialMediaProfile: builder.mutation({
      query: body => ({
        url: UPDATE_PROFILE,
        method: "PATCH",
        body
      })
    }),
    acceptConnectionRequest: builder.mutation({
      query: id => ({
        url: ACCEPT_CONNECTION_REQUEST.replace(":id", id),
        method: "POST"
      })
    }),
    rejectConnectionRequest: builder.mutation({
      query: id => ({
        url: REJECT_CONNECTION_REQUEST.replace(":id", id),
        method: "DELETE"
      })
    }),
    createSocialUserChat: builder.mutation({
      query: body => ({
        url: SOCIAL_USER_CHAT,
        method: "POST",
        body: body
      })
    })
  }),
  overrideExisting: false
})

export const {
  useGetSocialFeedQuery,
  useGetUserConnectionsQuery,
  useGetSuggestionlistQuery,
  useDeleteUserConnectionMutation,
  useAddUserConnectionMutation,
  useCreatePostMutation,
  useTogglePostLikeMutation,
  useAddCommentToPostMutation,
  useGetUserPostsQuery,
  useGetSocialMediaProfileQuery,
  useUpdateSocialMediaProfileMutation,
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation,
  useCreateSocialUserChatMutation
} = socialMedaiApiSlice

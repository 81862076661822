import React from "react"
import {
  Flex,
  Layout,
  Avatar,
  Typography,
  Popover,
  List,
  Badge,
  Dropdown
} from "antd"
import appLogo from "../../assets/common/logo.png"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { UserOutlined } from "@ant-design/icons"
import { NotificationIcon } from "../../assets/rawSvgs"
import { useLocation } from "react-router-dom"
import "./styles.scss"
import {
  getUser,
  removeJWTToken,
  removeRefreshToken,
  removeUser
} from "../../api/auth"
import { ROLES } from "../../constants/roles"

const { Text, Link } = Typography
const { Header } = Layout

const data = [
  {
    title: "Angelo Libero"
  },
  {
    title: "Davis Carder"
  },
  {
    title: "Ant Design Title 3"
  }
]

const content = (
  <div style={{ width: "412px" }}>
    <List
      itemLayout="horizontal"
      dataSource={data}
      size="large"
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={32}
                icon={<UserOutlined />}
                style={{ backgroundColor: "#3DC2DC" }}
              />
            }
            title={
              <Flex justify="space-between">
                {item.title}
                <Flex gap="small">
                  {" "}
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    5:20pm 20 Jan 2022
                  </Text>
                  <Badge status="success" />
                </Flex>
              </Flex>
            }
            description="Your profile has been created successfully."
          />
        </List.Item>
      )}
    />
  </div>
)

const HeaderNav = () => {
  const { navigateTo } = useRouterNavigation()
  const { pathname } = useLocation()
  const location = useLocation()

  const isEmployer = getUser()?.role === ROLES.EMPLOYER

  const handleLogOut = () => {
    removeJWTToken()
    removeRefreshToken()
    removeUser()
    navigateTo(Routes.LOGIN)
  }

  const items = [
    {
      label: (
        <Link onClick={() => navigateTo(Routes.SELECT_PLATFORM)}>
          Switch Services
        </Link>
      ),

      key: "0"
    },
    {
      label: <Link onClick={() => navigateTo(Routes.SETTINGS)}>Settings</Link>,
      key: "1"
    },
    {
      label: <Link onClick={handleLogOut}>Logout</Link>,
      key: "2"
    }
  ]
  // Add "Profile" option if user is on Worker Jobs route
  if (!isEmployer) {
    items.unshift({
      label: (
        <Link onClick={() => navigateTo(Routes.WORKER_PROFILE)}>Profile</Link>
      ),
      key: "profile"
    })
  }
  return (
    <Header className="HeaderWrapper">
      <div className="HeaderBounds">
        <Flex justify="space-between" align="center">
          <Flex gap={48} align="center">
            <div className="AppLogoWrap cursor-pointer">
              <img
                src={appLogo}
                alt="app logo"
                onClick={() => navigateTo(Routes.DASHBOARD)}
              />
            </div>
            <Flex gap="large">
              <Text
                //className="font-weight-500 cursor-pointer"
                className={`font-weight-500 cursor-pointer ${location.pathname === Routes.EMPLOYER_JOBS || location.pathname === Routes.WORKER_JOBS ? "selected" : ""}`}
                onClick={() =>
                  navigateTo(
                    isEmployer ? Routes.EMPLOYER_JOBS : Routes.WORKER_JOBS
                  )
                }
              >
                Gigs
              </Text>
              <Text
                // className="font-weight-500 cursor-pointer"
                className={`font-weight-500 cursor-pointer ${location.pathname === Routes.CHAT ? "selected" : ""}`}
                onClick={() => navigateTo(Routes.CHAT)}
              >
                Messages
              </Text>
            </Flex>
          </Flex>
          <>
            <Flex gap="middle" align="center">
              <Popover
                content={content}
                trigger="click"
                style={{ width: "360px" }}
                overlayInnerStyle={{ padding: "0px" }}
              >
                <span className="d-inline-flex">
                  <NotificationIcon />
                </span>
              </Popover>
              <Dropdown
                menu={{
                  items
                }}
                trigger={["click"]}
                overlayStyle={{ width: "165px" }}
              >
                <a
                  style={{ lineHeight: "32px" }}
                  onClick={e => e.preventDefault()}
                >
                  <Avatar
                    size={32}
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#3DC2DC" }}
                  />
                </a>
              </Dropdown>
            </Flex>
          </>
        </Flex>
      </div>
    </Header>
  )
}

export default HeaderNav

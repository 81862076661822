import { Button, Flex, Radio, Spin } from "antd"
import Header from "./Header"
import { useState } from "react"
import { useGetWorkerProfileInfoQuery } from "../../../api/apiSlices/workerApiSlice"
import RecurringAvailabilityForm from "./RecurringAvailabilityForm"
import CustomAvailabilityForm from "./CustomAvailabilityForm"
import "./styles.scss"

const DESCRIPTION =
  "Choose when you're available to work. Schedule your availability for specific dates or set recurring times so clients know when to reach out."

const options = [
  { label: "Recurring", value: "recurring" },
  { label: "Custom", value: "custom" }
]

const Availability = ({
  wrapperClassName = "",
  onNextClick = null,
  onPreviousClick = null,
  onFinishOnboarding
}) => {
  const [view, setView] = useState("recurring")
  const { data, isLoading, refetch } = useGetWorkerProfileInfoQuery()

  return (
    <>
      <div className={wrapperClassName}>
        {/* <Spin spinning={isLoading}> */}
          <Header title="Add your availability" description={DESCRIPTION} />
          <div className="availabilityContainer">
            <Radio.Group
              options={options}
              onChange={event => setView(event.target.value)}
              value={view}
              optionType="button"
            />
            {!isLoading && view === "recurring" && (
              <RecurringAvailabilityForm data={data} refetch={refetch} />
            )}
            {!isLoading && view === "custom" && (
              <CustomAvailabilityForm data={data} refetch={refetch} />
            )}
          </div>
        {/* </Spin> */}
      </div>
      {onNextClick && (
        <Flex justify="space-between">
          <Button type="default" onClick={onPreviousClick}>
            Previous
          </Button>
          <Button type="primary" onClick={onFinishOnboarding}>
            Finish Onboarding
          </Button>
        </Flex>
      )}
    </>
  )
}

export default Availability

import { SearchOutlined } from "@ant-design/icons"
import { Drawer, Empty, Flex, Input, Spin, Tabs } from "antd"
import { useDebounce } from "../../hooks/useDebounce"
import { useState } from "react"
import "./TopBar.scss"
import { useSearchCommunitiesQuery } from "../../api/apiSlices/communityApiSlice"
import CommunityListItem from "../communities-manage-banner/components/CommunityListItem"

export default function SearchBar({ open, onClose }) {
  const [search, setSearch] = useState("")

  const debouncedSearchTerm = useDebounce(search, 500)

  const { data: { results } = { results: [] }, isLoading } =
    useSearchCommunitiesQuery({
      searchText: debouncedSearchTerm
    })

  const items = [
    {
      key: "1",
      label: "People"
    },
    {
      key: "2",
      label: "Communities"
    }
  ]
const showEmptyMessage = search.length === 0 ? "Find by searching" : "No results found"
  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title=""
      width={400}
      extra={
        <Input
          value={search}
          placeholder="Search"
          prefix={<SearchOutlined />}
          onChange={event => setSearch(event.target.value)}
        />
      }
      className="searchBar"
    >
     
      <Flex vertical>
     
        <Tabs defaultActiveKey="2" items={items} />
        <Spin spinning={isLoading}>
       
          <Flex vertical gap={16} style={{ overflowY: "auto" }}>
          {search.length !== 0 && results?.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                vertical
                style={{ height: "100%", marginTop: "100px" }}
              >
                <Empty description={showEmptyMessage} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            ) : search.length === 0 && (
              <Empty description={showEmptyMessage} />
            )}
            {results?.map(community => (
              <CommunityListItem
                key={community.community_id}
                id={community.community_id}
                name={community.name}
                membersCount={community.member_count}
                displayImage={community?.display_image?.url}
                visibility={community.visibility}
              />
            ))}
          </Flex>
        </Spin>
      </Flex>
    </Drawer>
  )
}

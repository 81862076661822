import React, { useState } from "react"
import { Avatar, Button, Empty, Flex, Modal, Typography } from "antd"
import { useDebounce } from "../../hooks/useDebounce"

import {
  ExclamationCircleOutlined,
  UsergroupDeleteOutlined
} from "@ant-design/icons"
import {
  useLeaveCommunityMutation,
  useMyJoinedCommunitiesQuery
} from "../../api/apiSlices/communityApiSlice"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../constants/routes"

const { Text } = Typography

const JoinedCommunities = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")

  const debouncedSearchTerm = useDebounce(search, 500)

  const [modal, contextHolder] = Modal.useModal()

  const {
    data: { results: communities, count } = { results: [], count: 0 },
    refetch: refetchCommunities,
    isLoading
  } = useMyJoinedCommunitiesQuery(debouncedSearchTerm)

  const [leaveCommunity] = useLeaveCommunityMutation()

  const showModal = () => {
    if (count === 0) return
    setOpen(true)
  }

  const handleLeaveCommunity = async id => {
    await leaveCommunity(id)
      .unwrap()
      .then(() => {
        refetchCommunities()
      })
  }

  const showRemoveModal = id => {
    modal.confirm({
      title: "Leave Community",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to leave this community? This action cannot be undone.",
      okText: "Leave",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () => handleLeaveCommunity(id),
      centered: true
    })
  }

  return (
    <>
      <Modal
        open={open}
        centered
        title="Joined Communities"
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div className="feedbackModal">
          <Flex vertical gap="middle">
            {/* <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
              value={search}
              onChange={e => setSearch(e.target.value)}
            /> */}
            {!isLoading && communities?.length === 0 && (
              <Flex justify="center" align="center" vertical>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            )}
            {communities?.map((community, index) => {
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex
                    gap="small"
                    onClick={() =>
                      navigate(
                        Routes.COMMUNITY.replace(":id", community.community_id)
                      )
                    }
                    className="cursor-pointer"
                  >
                    <Avatar
                      size="small"
                      icon={<UsergroupDeleteOutlined />}
                      src={community?.display_image?.url}
                    />
                    <Text>{community.name}</Text>
                  </Flex>
                  <Button
                    onClick={() => showRemoveModal(community.community_id)}
                    variant="link"
                  >
                    Leave
                  </Button>
                </Flex>
              )
            })}
          </Flex>
        </div>
      </Modal>
      <Flex
        justify="space-between"
        style={{
          marginBottom: "16px",
          padding: "12px 24px",
          backgroundColor: "white",
          borderRadius: "8px"
        }}
      >
        <Flex gap={8}>
          <Text>Joined Communities:</Text>
          <Text strong>{count}</Text>
        </Flex>
        {count !== 0 && (
          <Text onClick={showModal} strong className="cursor-pointer">
            View
          </Text>
        )}
      </Flex>

      {contextHolder}
    </>
  )
}

export default JoinedCommunities

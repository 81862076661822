const AUTH = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  OTP: "/otp",
  TWOFA: "/two-factor-authentication",
  RESET_PASSWORD: "/reset-password/:id/:token",
  PUBLIC_MESSAGE: "/info",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-condition"
}

const APP_ROUTES = {
  SETTINGS: "/settings",
  PROFILE: "/profile/:id",
  HOME: "/social-media-feed",
  WORKER_PROFILE: "/worker-profile",
  USER_EXPERIENCE: "/user-experience",
  USER_EXPERIENCE_EDIT:"/user-experience/:step",
  EMPLOYER_JOBS: "/employer-jobs",
  WORKER_JOBS: "/worker-jobs",
  DASHBOARD: "/flextime-dashboard",
  JOBS: "/jobs",
  LANDING: "/",
  SELECT_PLATFORM: "/select-platform",
  CREATION: "/job-creation",
  JOB_DETAIL: "/jobs/:id",
  COMMUNITY: "/community/:id",
  CHAT: "/chat-streaming",
  COMMUNITIES: "/communities",
  ADMIN_CHAT: "/admin-chat",
  DONATION: "/donations"
}

export const Routes = {
  ...AUTH,
  ...APP_ROUTES
}

import React, { useState , useEffect } from "react"
import { Spin, Steps } from "antd"
import CreateProfile from "./components/CreateProfile"
import ProfessionalExperience from "./components/ProfessionalExperience"
import Skills from "./components/Skills"
import Availability from "./components/Availability"
import { useFinishOnboardingMutation } from "../../api/apiSlices/workerApiSlice"
import { useNavigate ,useParams} from "react-router-dom"
import { Routes } from "../../constants/routes"
import Education from "./components/Education"
import "./styles.scss"

const UserExperience = ({current}) => {
  const { step } = useParams();
  const [currentStep, setCurrentStep] = useState(0)
  const onChangeStep = step => setCurrentStep(step)
  const [finishOnboarding, { isLoading }] = useFinishOnboardingMutation()
  let navigate = useNavigate()
 
  useEffect(() => {
    if (step !== undefined) {
      const parsedStep = parseInt(step); 
      if (!isNaN(parsedStep)) {  
        setCurrentStep(parsedStep); 
        
      } else {
        console.error("Invalid step:", step);
      }
    }
  }, [step]);

  const onNextClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const onPreviousClick = () => {
    setCurrentStep(currentStep - 1)
  }

  const onFinishOnboarding = () => {
    finishOnboarding().then(() => {
      navigate(Routes.WORKER_PROFILE)
    })
  }
  const handleSkip = () => {
    onFinishOnboarding()
  }

  const skipStep = () =>{
    setCurrentStep(currentStep +1 );
  }

  return (
    <div className="userExperience">
      <Spin spinning={isLoading}>
        <Steps
          current={currentStep}
          items={[
            {
              title: "Create your profile"
            },
            {
              title: "Experience"
            },
            {
              title: "Education"
            },
            {
              title: "Characteristics"
            }
          ]}
          onChange={onChangeStep}
        />
        {currentStep === 0 && (
          <CreateProfile
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            handleSkip={handleSkip}
            skipStep={skipStep}
          />
        )}
        {currentStep === 1 && (
          <ProfessionalExperience
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            skipStep={skipStep}
          />
        )}
        {currentStep === 2 && (
         
          <Education
          wrapperClassName="onboardingSteps"
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          skipStep={skipStep}/>
        )}
       
         {currentStep === 3 && (
            <Skills
            wrapperClassName="onboardingSteps"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            skipStep={onFinishOnboarding}
            onFinishOnboarding={onFinishOnboarding}
          />
        )}


      </Spin>
    </div>
  )
}

export default UserExperience

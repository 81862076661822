import SettingsSection from "../components/settings-section/SettingsSection"
import { Avatar, Button, Flex, Spin, Typography } from "antd"
import { FacebookFilled, PlusOutlined } from "@ant-design/icons"
import { useGetProfileDataQuery } from "../../../api/apiSlices/userApiSlice"
import {
  GET_FACEBOOK_AUTH,
  REMOVE_FACEBOOK_CONNECTION
} from "../../../constants/endpoints"
import { main_api as axiosInstance } from "../../../api/axios_helper"
import "./styles.scss"

const { Title, Text } = Typography
const ConnectSocialAccounts = () => {
  const { data, isLoading, refetch } = useGetProfileDataQuery()

  const doMagic = async () => {
    try {
      const response = await axiosInstance.get(GET_FACEBOOK_AUTH)
      window.open(response.data.auth_url, "_blank", "noopener,noreferrer")
    } catch (error) {}
  }

  const undoMagic = async () => {
    try {
      await axiosInstance.get(REMOVE_FACEBOOK_CONNECTION)
      refetch()
    } catch (error) {}
  }
  return (
    <div className="tabContainer">
      <Spin spinning={isLoading}>
        <SettingsSection
          title="Cross Platform Post Sharing"
          description="Share your posts seamlessly across connected accounts."
        >
          <div className="actionSection">
            {(!data?.fb_account || !data?.fb_account.is_active) && (
              <Button icon={<PlusOutlined />} type="link" onClick={doMagic}>
                Connect Account
              </Button>
            )}
            {data?.fb_account?.is_active && (
              <Flex
                justify="space-between"
                style={{ width: "100%" }}
                align="flex-start"
              >
                <Flex gap="middle" align="center">
                  <Avatar size={40} icon={<FacebookFilled />} />
                  <Flex vertical>
                    <Title level={5}>{data?.fb_account?.fb_name}</Title>
                    <Text>Facebook</Text>
                  </Flex>
                </Flex>
                <Button type="link" danger onClick={undoMagic}>
                  Remove
                </Button>
              </Flex>
            )}
          </div>
        </SettingsSection>
      </Spin>
    </div>
  )
}

export default ConnectSocialAccounts

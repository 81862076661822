import { useFormik } from "formik"
import Header from "./Header"
import "./styles.scss"
import {
  useGetWorkerProfileInfoQuery,
  useUpdateWorkerSkillsMutation
} from "../../../api/apiSlices/workerApiSlice"
import * as Yup from "yup"
import { Button, Flex, Select, Spin } from "antd"
import { openNotification } from "../../../util/openNotifications"
import { useEffect } from "react"

export const profileValidationSchema = Yup.object({
  skills: Yup.array()
    .of(Yup.string().required("Skill cannot be empty")) 
    .min(1, "At least one skill is required")
})

const suggestedSkills = [
  "Digital Marketing",
  "Google Ads",
  "Social Media Management",
  "Microsoft Excel",
  "Figma Expert",
  "Newsletter",
  "Content Strategy"
]
const Skills = ({
  onNextClick = null,
  onPreviousClick = null,
  wrapperClassName = "",
  skipStep,
  onFinishOnboarding
}) => {
  const { data, isLoading, refetch } = useGetWorkerProfileInfoQuery()
  const [updateSkills] = useUpdateWorkerSkillsMutation()

  

  const { values, errors, touched, handleSubmit, handleBlur, setValues } =
    useFormik({
      initialValues: {
        skills: []
      },
      validationSchema: profileValidationSchema,
      onSubmit: async values => {
        try {
          await updateSkills(values).unwrap()
          refetch()
          onFinishOnboarding()
        } catch (error) {
          openNotification({
            type: "error",
            message: "Failed to update skills at this time"
          })
        }
      }
    })

   

  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        skills: data.skills.map(skill => skill.name)
      })
    }
  }, [data])

  const filteredSkills = suggestedSkills.filter(
    skill => !values.skills.includes(skill)
  )

  return (
    <>
      <div className={wrapperClassName}>
        <Spin spinning={isLoading}>
          <Header
            title="Add Characteristics"
            description="List your key Characteristics relevant to your role."
          />
          <Flex vertical>
            <label className={`Input_label`}>Characteristics</label>
            <Select
              name="skills"
              mode="tags"
              value={values.skills}
              placeholder="Enter skills here"
              onChange={value => setValues({ ...values, skills: value })}
              onDeselect={value => {
                setValues({
                  ...values,
                  skills: values.skills.filter(skill => skill !== value)
                })
              }}
              handleBlur={handleBlur}
            />
            {touched["skills"] && errors?.skills && (
              <p className="Input_errorMessage">{errors?.skills}</p>
            )}
          </Flex>
          <div className="suggestedSkills">
            <h6>Suggested Characteristics</h6>
            <Flex gap={8} wrap>
              {filteredSkills.map(skill => (
                <div
                  className="skill"
                  onClick={() =>
                    setValues({ ...values, skills: [...values.skills, skill] })
                  }
                >
                  {skill}
                </div>
              ))}
            </Flex>
          </div>
        </Spin>
      </div>
      {onNextClick && (
         <Flex justify='space-between'>
         <Button type='text' onClick={skipStep} >Skip this step</Button>
       
         <Flex gap='12px' > 
         <Button onClick={onPreviousClick}>Back</Button>
         <Button  type='primary'  onClick={handleSubmit} >Done</Button>
         </Flex>
  
     </Flex>
      )}
    </>
  )
}

export default Skills

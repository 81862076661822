
import React from 'react';
import { Row, Col, Typography, Tag, Button, Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './styles.module.scss'; 

const { Title, Text } = Typography;

const ReviewAndSubmit = ({ onPreviousClick, onChangeStep, formData ,onSubmit,errors, touched ,isSubmitting}) => {
  return (
    <div className={styles.container}>
      <Title level={3} className={styles.title} >
        Review and Submit
      </Title>

      <Row gutter={16} className={styles.gutter}>
        <Col span={24}>
          <Card
            title={<Title level={4} className={styles.title}>{`Survey`}</Title>}
            extra={
              <Button
                type="link"
                icon={<EditOutlined style={{ color: 'grey' }} />}
                onClick={() => onChangeStep(0)} 
              />
            }
           
             className={styles.cardBody}  style={{ whiteSpace: 'pre-wrap' }}>
             {formData?.description}
          </Card>
        </Col>
      </Row>

     
      <Row gutter={16}>
        <Col span={24}>
          <Card
            title={<Title level={4} className={styles.title}>{`Skills`}</Title>}
            extra={
              <Button
                type="link"
                icon={<EditOutlined style={{ color: 'grey' }} />}
                onClick={() => onChangeStep(1)} 
              />
            }
            className={styles.card}
          >
            {formData.skills && formData.skills.length > 0 ? (
              formData.skills.map((skill, index) => (
                <Tag
                  key={index}
                  color="blue"
                  className={styles.tag}
                >
                  {skill}
                </Tag>
              ))
            ) : (
              <Text>No skills added.</Text>
            )}
          </Card>
        </Col>
      </Row>

     
      <Row gutter={16} className={styles.marginTop}>
        <Col span={24}>
          <Card
            title={<Title level={4} className={styles.title}>{`Job Details`}</Title>}
            extra={
              <Button
                type="link"
                icon={<EditOutlined style={{ color: 'grey' }} />}
                onClick={() => onChangeStep(2)} 
              />
            }
            className={`${styles.card} ${styles.jobDetails}`}
          
          >
            <Row gutter={16}>
              <Col span={8}>
             
                <Text className={styles.TextColor}>Hourly Rate:</Text>
                <Text style={{ display: 'block' }}>{formData?.rateMin || "N/A"} - {formData?.rateMax || "N/A"}</Text>
              </Col>
              <Col span={8}>
                <Text className={styles.TextColor}>Number of Workers:</Text>
                <Text style={{ display: 'block' }}>{formData?.workersNeeded || "N/A"}</Text>
              </Col>
              <Col span={8}>
                <Text className={styles.TextColor} >Project Duration:</Text>
                <Text style={{ display: 'block' }}>{formData?.dateRange[0]  || "N/A"} -{formData?.dateRange[1]  || "N/A"}</Text>
              </Col>
            </Row>
            <Row gutter={16} className={styles.marginTopSmall}>
              <Col span={8}>
                <Text className={styles.TextColor}>Location:</Text>
                <Text style={{ display: 'block' }}>{formData?.location || "N/A"}</Text>
              </Col>
              <Col span={16}>
                <Text className={styles.TextColor} style={{ whiteSpace: 'pre-wrap' }}>Additional Notes:</Text>
               <Text style={{ display: 'block' }}>{formData?.notes || "No additional notes."}</Text>
           
              </Col>

            </Row>
          </Card>
        </Col>
      </Row>

      
      <Row justify="end" className={styles.buttonContainer}>
        <Col>
          <Button
            onClick={onPreviousClick}
            className={styles.backButton}
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={onSubmit}
            htmlType='submit'
            className={styles.submitButton}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewAndSubmit;

export const getQueryParams = search => {
  const queryParams = new URLSearchParams(search)
  const params = {}

  for (const [key, value] of queryParams.entries()) {
    params[key] = value
  }

  return params
}

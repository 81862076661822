import React from 'react'
import { Modal , Button , Flex} from 'antd'
import { useState } from 'react';
import { Typography } from 'antd';
import styles from './styles.scss';
import { Group ,HealthCare,HeartShape ,SecurityLock } from '../../assets/rawSvgs';

const { Title , Text } = Typography;



function DonationSuggestion() {
  

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    return (
      <>
        <Button type="primary" onClick={showModal}>
          Open Modal
        </Button>

        <Modal title="Make a Difference ❤️" open={isModalOpen}  className="modalWrappermain" 
         footer={[
            <Button key="Skip" onClick={handleCancel}>
               Skip for now
            </Button>,
            <Button key="Donate" type="primary"  onClick={handleOk}>
                Donate Now
            </Button>,
           
          ]}
        >
             <div  >
                <Flex gap='12px' style={modalContentStyle}>
                <Flex vertical  style={{backgroundColor:'rgba(255, 244, 234, 1)'}}>
                       <Title level={5} style={{marginTop:'15.5px'}}>Donate to a Cause You Care About</Title>
                       <Text style={{marginBottom:'15.5px'}}>Thank you for your purchase! Would you like to make an impact by donating to a nonprofit of your choice? 
                        Even a small contribution can make a big difference.</Text>
                </Flex>

                <Flex > 
                        <Group/>
                </Flex>
                </Flex>
             </div>


             <div style={paddingWrapperStyle}>
                <Flex vertical gap='12px'>
                    <Flex gap='8px'> 
                        <HealthCare/>
                        <Text>Support a charity you believe in</Text>
                        
                    </Flex>
                    <Flex gap='8px'>
                        <SecurityLock/>
                        <Text>Help create lasting change</Text>
                       
                    </Flex>
                   
                  <Flex gap='8px'>
                       <HeartShape/>
                       <Text>Easy and secure donations</Text>
                  </Flex>
                    
                </Flex>
             </div>
        
      

           












        </Modal>
      </>
    );
}

export default DonationSuggestion


// Style objects for inline styles
const modalContentStyle = {
  backgroundColor: 'rgba(255, 244, 234, 1)',
  paddingBottom: '12px',
  paddingRight: '24px',
  paddingLeft: '24px',
};
const paddingWrapperStyle = {
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingRight: '24px',
  paddingLeft: '24px',
};
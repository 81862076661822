import React, { useState } from 'react';
import { Steps } from 'antd';
import CreateProfile from './components/CreateProfile';
import Skills from './components/Skills';
import JobDetails from './components/JobDetails';
import ReviewAndSubmit from './components/ReviewAndSubmit';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../constants/routes';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { useCreateGigMutation } from '../../api/apiSlices/workerApiSlice';
import { openNotification } from "../../util/openNotifications"
import { jobCreationSchema, jobDetails } from '../../schemas/JobCreationSchema';



const JobCreation = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const onChangeStep = (step) => setCurrentStep(step);
  const navigate = useNavigate();
  const [createGig] = useCreateGigMutation();

  const initialValues = {
    professional_role: '',
    description: '',
    skills: [],
      rateMin: '',
      rateMax: '',
      workersNeeded: '',
      dateRange:[],
      location: '',
      notes: '',
    
  };

  const formik = useFormik({
    initialValues,
    validationSchema: jobCreationSchema,
      onSubmit: async (values ,{setSubmitting}) => {
        setSubmitting(true);
    const payload = {
      title: formik.values.professional_role || "", 
      description: formik.values.description || "", 
      skills: formik.values.skills || [], 
      budget_min: formik.values.rateMin || 0, 
      budget_max: formik.values.rateMax || 0, 
      start_date: values.dateRange[0] || null, 
      end_date: values.dateRange[1] || null, 
      location: formik.values.location || "", 
      number_of_workers: formik.values.workersNeeded || 0, 
      additional_notes: formik.values.notes || ""
    };  
      try {
        await createGig(payload).unwrap(); 
        navigate(Routes.EMPLOYER_JOBS);

      }
      catch (err) {
        openNotification({
          type: "error",
          message: "Failed to update info at this time"
        })
      }
      finally{
        setSubmitting(false);
      }
     },
  });


  const { values, handleChange, handleBlur, handleSubmit, errors, touched, setValues, setFieldValue ,isValid} = formik;
 
  const onNextClick = () => {
    
    let currentSchema;
  
    if (currentStep === 0) {
      currentSchema = jobCreationSchema.pick(['professional_role', 'description']);
    } else if (currentStep === 1) {
      currentSchema = jobCreationSchema.pick(['skills']);
    } else if (currentStep === 2) {
      currentSchema = jobCreationSchema.pick(['rateMin', 'rateMax','workersNeeded','dateRange','location','notes']);
    }
  
    currentSchema.validate(values, { abortEarly: false }).then(() => {
     
 
      setCurrentStep(currentStep + 1);
    }).catch((validationErrors) => {    
     
      const errors = validationErrors.inner.reduce((acc, error) => {

        acc[error.path] = error.message;
        console.log("errors",error)
        return acc;
      }, {});
      formik.setErrors(errors);
  
      const touchedFields = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = true;
        console.log("touchedfieldssss",acc)
        return acc;
      }, {});
      console.log("touchedfieldssss",touchedFields)
      formik.setTouched({
        ...touched,
        ...touchedFields
      });
    });
  
  
  }


  const onPreviousClick = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className={styles.user} >
      <Steps
        current={currentStep}
        items={[
          { title: 'Job Information' },
          { title: 'Characteristics' },
          { title: 'Job Details' },
          { title: 'Review and Submit' },
        ]}
      
        style={{marginBottom:" 24px"}}
      />

      {currentStep === 0 && (
        <CreateProfile
          initialValues={values}          
          onNextClick={onNextClick}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}  
          setValues={setValues}  
          isValid={isValid}
        />
      )}

      {currentStep === 1 && (
        <Skills
          initialValues={values}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setValues={setValues}    
        />
      )}

      {currentStep === 2 && (
        <JobDetails
          values={values}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue} 
           setValues={setValues} 
        />
      )}

      {currentStep === 3 && (
        <ReviewAndSubmit
          formData={values}
          onPreviousClick={onPreviousClick}
          onSubmit={handleSubmit}
          errors={errors}
          touched={touched}
          onChangeStep={onChangeStep}
          isSubmitting={formik.isSubmitting}
        />
      )}
    </div>
  );
};

export default JobCreation;

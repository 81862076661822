import { Layout } from "antd"
import React from "react"
import { Outlet } from "react-router-dom"
import DonationHeaderNav from "../../components/DonationHeaderNav/DonationHeaderNav"

const { Content } = Layout

export default function DonationLayout() {
  return (
    <Layout>
      <DonationHeaderNav />
      <Content className="ContentContainer">
        <div className="ContentWrap">
          <Outlet />
        </div>
      </Content>
    </Layout>
  )
}

import "./styles.scss"

const Header = ({ title, description }) => (
  <div className="header_Wrapper">
    <h5 className="title">{title}</h5>
    <p className="description">{description}</p>
  </div>
)

export default Header

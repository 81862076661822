import { WarningTwoTone } from "@ant-design/icons"
import { Typography } from "antd"
import Title from "antd/es/typography/Title"
import "./styles.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"

export default function PublicMessage() {
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!state) {
      navigate("/")
    }
  }, [state])

  return (
    <div className="wrapper">
      <WarningTwoTone style={{ fontSize: "40px" }} />
      <Title>{state?.title}</Title>
      {state?.message && <Typography>{state.message}</Typography>}
    </div>
  )
}

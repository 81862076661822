import React from "react"
import { Button, Typography } from "antd"

import CustomInput from "../../components/customInput/CustomInput"

import styles from "../login/auth.module.scss"

const { Title, Text } = Typography

const TwoFactorAuthentication = () => {
  return (
    <div className={styles.AuthContainer}>
      <div className={styles.AuthWrapper} style={{ height: "458px" }}>
        <div className={`${styles.forgotContent} gap-4`}>
          <div className="text-center mt-10">
            <Title level={3}>Choose 2FA method</Title>
            <Text>Enter your email or phone number to receive OTP.</Text>
          </div>
          <CustomInput label="Email" placeholder="Enter Email" />
          <div className={styles.OrDivider}>
            <hr />
            <p>OR</p>
            <hr className="line" />
          </div>
          <CustomInput label="Phone Number" placeholder="Enter Phone" />
        </div>
        <Button type="primary" className="loginBtn mb-10" block>
          Send OTP
        </Button>
      </div>
    </div>
  )
}

export default TwoFactorAuthentication

import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  UpOutlined,
  UserAddOutlined
} from "@ant-design/icons"
import {
  Avatar,
  Button,
  Flex,
  Image,
  Modal,
  Spin,
  Switch,
  Typography,
  Upload,
  Popover,
  Select
} from "antd"
import {
  useAddMediaMutation,
  useDeleteMediaMutation
} from "../../../../api/apiSlices/settingsApiSlice"
import { useState } from "react"
import TextArea from "antd/es/input/TextArea"
import { getBase64 } from "../../../../util/GetBase64"
import {
  useCreatePostMutation,
  useGetSocialMediaProfileQuery
} from "../../../../api/apiSlices/socialMediaApiSlice"
import { openNotification } from "../../../../util/openNotifications"
import { getUser } from "../../../../api/auth"
import PopoverContent from "../AI-post-generation/PopoverContent"
import { Sparkle } from "../../../../assets/rawSvgs"
import AttachCommunities from "../attach-communities/AttachCommunities"
import { useGetConnectedFacebookPagesQuery } from "../../../../api/apiSlices/userApiSlice"
import { Routes } from "../../../../constants/routes"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

const { Text } = Typography
// Maximum file size in bytes (10 MB)
const MAX_FILE_SIZE = 10 * 1024 * 1024

export default function CreatePost({ onClose, communityId = null }) {
  const currentUser = getUser()
  const { data: userProfile } = useGetSocialMediaProfileQuery(currentUser.id, {
    refetchOnMountOrArgChange: true
  })
  const { data: facebookPages, isLoading: isLoadingFbPages } =
    useGetConnectedFacebookPagesQuery(null, { refetchOnMountOrArgChange: true })
  const [text, setText] = useState("")
  const [keywords, setKeywords] = useState([])
  const [showPostSettings, setShowPostSettings] = useState(false)
  const [postSettings, setPostSettings] = useState({
    comments_enabled: true,
    likes_enabled: true
  })
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewVideo, setPreviewVideo] = useState()
  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false)
  const [fileList, setFileList] = useState([])
  const [attachments, setAttachments] = useState([])
  const [addMedia] = useAddMediaMutation()
  const [createNewPost, { isLoading: isAddingPost }] = useCreatePostMutation()
  const [openAiOptions, setOpenAiOptions] = useState(false)
  const [deleteMedia] = useDeleteMediaMutation()
  const [shareWithCommunities, setShareWithCommunities] = useState(false)
  const [attachedCommunitiesIds, setAttachedCommunitiesIds] = useState([])
  const [facebookPageId, setFacebookPageId] = useState()
  const [postOnInsta, setPostOnInsta] = useState(false)
  const navigate = useNavigate()

  const handleOpenAiOptions = () => {
    setOpenAiOptions(true)
  }

  const handleCloseAiOptions = () => {
    setOpenAiOptions(false)
  }

  const handleInsertion = result => {
    setText(result)
  }

  const onChangePostSettings = (key, value) => {
    setPostSettings({ ...postSettings, [key]: value })
  }

  const onConfirmCreatePost = () => {
    const allfilesUpload = fileList.every(file => file.status === "done")

    if (!allfilesUpload) {
      openNotification({
        type: "warning",
        message: "Please wait until all files are fully uploaded"
      })
      return
    }
    if (text.length === 0 && attachments.length === 0) {
      openNotification({
        type: "error",
        message: "Post can't be empty"
      })
      return
    }
    const data = {
      content: text,
      attachments: attachments,
      keywords,
      ...postSettings
    }
    if (communityId) {
      data.community = parseInt(communityId)
    }
    if (attachedCommunitiesIds.length) {
      data.communities = attachedCommunitiesIds
    }
    if (postOnInsta && facebookPageId) {
      data.page_id = facebookPageId
    }

    createNewPost(data)
      .unwrap()
      .then(() => {
        openNotification({
          type: "success",
          message: "Post created successfully"
        })
        onClose({ newPostCreated: true })
      })
      .catch(error => {
        openNotification({
          type: "error",
          message:
            error?.message[0] ||
            error?.message ||
            "Failed to create post please try again!"
        })
      })
  }
  const togglePostSettings = () => setShowPostSettings(!showPostSettings)

  const handlePreview = async file => {
    if (file.response.type === "video") {
      setPreviewVideo({ url: file.response.url, type: file.type })
      setIsVideoPreviewOpen(true)
      return
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handleUploadFile = async ({ file, onSuccess, onError }) => {
    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData)
      setAttachments(prev => [...prev, data?.data])
      onSuccess(data.data, file)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid
            ? { ...item, thumbUrl: data?.data?.url, status: "done" }
            : item
        )
      )
    } catch (error) {
      onError(error)
      setFileList(prevList =>
        prevList.map(item =>
          item.uid === file.uid ? { ...item, status: "error" } : item
        )
      )
    }
  }

  const handleRemoveFile = async file => {
    setAttachments(prevList =>
      prevList.filter(item => item.object_key !== file.response.object_key)
    )
    await deleteMedia({ object_key: file.response.object_key })
  }

  const beforeUpload = file => {
    const isImage = file.type.startsWith("image/")
    const isVideo = file.type.startsWith("video/")

    if (!isImage && !isVideo) {
      openNotification({
        type: "error",
        message: "You can only upload image or video files!"
      })
      return Upload.LIST_IGNORE
    }

    if (file.size > MAX_FILE_SIZE) {
      openNotification({
        type: "error",
        message: "Upload exceeds the maximum supported size of 10MB"
      })
      return Upload.LIST_IGNORE
    }

    return true
  }

  const onCancelCreatePost = () => {
    const promises = attachments.map(attachment => {
      return deleteMedia({ object_key: attachment.object_key })
    })
    onClose({ promises })
  }

  const openShareWithCommunites = () => setShareWithCommunities(true)
  const closeShareWithCommunites = () => setShareWithCommunities(false)

  const navigateToConnectAccount = () => {
    navigate(Routes.SETTINGS + "?tab=connect-social-accounts")
    onCancelCreatePost()
  }

  return (
    <Modal
      title="Create Post"
      icon={<ExclamationCircleOutlined />}
      centered
      open
      maskClosable={false}
      className="CustomModalWrapper"
      cancelText="Cancel"
      okText="Post"
      onCancel={onCancelCreatePost}
      onOk={onConfirmCreatePost}
      confirmLoading={isAddingPost}
      okButtonProps={{ disabled: isAddingPost }}
      width={600}
    >
      <div className="postModal">
        <Spin spinning={false}>
          <Flex justify="space-between">
            <div className="info">
              <Avatar
                size="small"
                icon={<UserAddOutlined />}
                src={userProfile?.profile_picture?.url}
              />
              <p className="title">{userProfile?.name}</p>
            </div>
            {!communityId && (
              <Button
                type="default"
                className="addCommunitiesBtn"
                onClick={openShareWithCommunites}
              >
                <PlusOutlined />{" "}
                {attachedCommunitiesIds.length > 0
                  ? attachedCommunitiesIds.length
                  : "Add"}{" "}
                Communities
              </Button>
            )}
            <AttachCommunities
              setAttachedCommunitiesIds={setAttachedCommunitiesIds}
              attachedCommunitiesIds={attachedCommunitiesIds}
              open={shareWithCommunities}
              onClose={closeShareWithCommunites}
            />
          </Flex>

          <div
            className="textArea"
            style={{
              position: "relative",
              gap: "5px",
              marginTop: "12px",
              marginBottom: "12px"
            }}
          >
            <TextArea
              value={text}
              onChange={e => setText(e.target.value)}
              placeholder="What’s on your mind?"
              autoSize={{
                minRows: 1,
                maxRows: 7
              }}
              className="customInput"
              style={{
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingRight: "12px",
                paddingLeft: "12px"
              }}
            />
            <Popover
              placement="rightTop"
              trigger="click"
              overlayClassName="custom-popover borderSetting"
              content={
                <PopoverContent
                  closePopover={handleCloseAiOptions}
                  handleInsertion={handleInsertion}
                />
              }
              open={openAiOptions}
              onOpenChange={setOpenAiOptions}
            >
              <div
                className="popoverButton"
                style={{
                  position: "absolute",
                  top: "3px",
                  right: "10px",
                  cursor: "pointer",
                  backgroundColor: "#F5F5F5",
                  padding: "6px",
                  borderRadius: "6px"
                }}
                onClick={handleOpenAiOptions}
              >
                <Sparkle />
              </div>
            </Popover>
          </div>
          <Flex vertical style={{ marginBottom: "12px" }}>
            <label className={`Input_label`}>Tags</label>
            <Select
              name="keywords"
              mode="tags"
              value={keywords}
              placeholder="Add tags"
              onChange={value =>
                setKeywords(
                  value.map(tag => tag.trim()).filter(tag => tag !== "")
                )
              }
              onDeselect={value =>
                setKeywords(prev => prev.filter(keyword => keyword !== value))
              }
            />
          </Flex>
          <div className="fileUpload">
            <Upload
              listType="picture-card"
              accept="image/*,video/*"
              customRequest={handleUploadFile}
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleFileChange}
              onRemove={handleRemoveFile}
              beforeUpload={beforeUpload}
            >
              <Flex vertical gap="small" align="center">
                <PlusOutlined />
                Attach Media
              </Flex>
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none"
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: visible => setPreviewOpen(visible),
                  afterOpenChange: visible => !visible && setPreviewImage("")
                }}
                src={previewImage}
              />
            )}
            {isVideoPreviewOpen && (
              <Modal
                open={isVideoPreviewOpen}
                footer={null}
                onCancel={() => setIsVideoPreviewOpen(false)}
                width={800}
                centered
                wrapperStyle={{
                  display: "none"
                }}
              >
                <video controls width="100%" className="videoPreview">
                  <source src={previewVideo.url} type={previewVideo.type} />
                  Your browser does not support the video tag.
                </video>
              </Modal>
            )}
          </div>
          <div className="settings">
            <Button
              icon={showPostSettings ? <UpOutlined /> : <DownOutlined />}
              iconPosition="end"
              onClick={togglePostSettings}
            >
              Post Settings
            </Button>
            {showPostSettings && (
              <div className="postSettingContainer">
                <div className="postSettings">
                  <Text>Hide number of likes on this post</Text>
                  <Switch
                    value={postSettings.show_likes_count}
                    onChange={status =>
                      onChangePostSettings("likes_enabled", status)
                    }
                  />
                </div>
                <div className="postSettings">
                  <Text>Turn off commenting</Text>
                  <Switch
                    value={postSettings.comments_enabled}
                    onChange={status =>
                      onChangePostSettings("comments_enabled", status)
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <Flex justify="space-between" vertical gap={8}>
            <Flex gap={8}>
              <Switch
                value={postOnInsta}
                onChange={status => setPostOnInsta(status)}
              />
              <>Post on instagram </>
            </Flex>
            {postOnInsta && !userProfile?.fb_account && (
              <Text>
                No account connected{" "}
                <Button type="link" onClick={() => navigateToConnectAccount()}>
                  Click here to link your account
                </Button>
              </Text>
            )}
            {!isLoadingFbPages && postOnInsta && userProfile?.fb_account && (
              <Select
                value={facebookPageId}
                placeholder="Select Facebook Page"
                onChange={value => setFacebookPageId(value)}
                options={facebookPages?.results?.map(page => ({
                  value: page.id,
                  label: page.name
                }))}
                style={{ width: "40%" }}
              />
            )}
          </Flex>
        </Spin>
      </div>
    </Modal>
  )
}

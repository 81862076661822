import { Avatar, Flex, Typography } from "antd"
import moment from "moment"
import "./postCard.scss"
import { useNavigate } from "react-router-dom"

const { Title, Text } = Typography

export default function CommunityHeader({
  communitDetail,
  authorDetail,
  createdAt
}) {
  const navigate = useNavigate()

  const navigateTo = () => {
    navigate(`/community/${communitDetail.community_id}`)
  }
  return (
    <Flex align="center" gap="12px" className="community-card-header">
      <div className="image-wrapper" onClick={navigateTo}>
        <Avatar
          size={50}
          src={communitDetail?.display_image?.url}
          className="community-image"
        />
        <div className="user-image">
          <Avatar
            size={32}
            src={authorDetail?.profile_picture?.url}
            className="user"
          />
        </div>
      </div>

      <Flex vertical>
        <Title level={5}>{communitDetail.name}</Title>
        <Text>{authorDetail?.name || "Anonymous"}</Text>
      </Flex>
      <div className="dot dot_small" />
      <Text>{moment(createdAt).fromNow()}</Text>
    </Flex>
  )
}

import React from 'react'
import { Layout, Flex, Typography } from 'antd';
import styles from './Footer.module.scss';
import appLogo from "../../assets/common/IconBackground.png"
import { TwitterOutlined, LinkedinFilled, FacebookFilled, GithubFilled } from '@ant-design/icons';
import { BasketBallIcon, PeaceSignIcon } from '../../assets/rawSvgs';





const { Text } = Typography;
const { Footer } = Layout;
const LandingFooter = () => {
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
      className={styles.FooterContainer}
    >
      <Flex className={styles.FooterWrapper}>
        <Flex vertical gap={32}>
          <div className={`${styles.DarkLogo} AppDarkLogo`}>
            <img src={appLogo} alt="app logo" />
          </div>
          <Flex gap={32} align='center'>
            <li className='nobullet'>Home</li>
            <li className='nobullet'>Flextime Force</li>
            <li className='nobullet'>Social Platform</li>
            <li className='nobullet'>E-commerce</li>
            <li className='nobullet'>Donate</li>
            <li className='nobullet'>Take Loan</li>
            <li className='nobullet'>Terms and Conditions</li>
          </Flex>
        </Flex>
        <Flex gap="large" align='center'>
          <TwitterOutlined style={{ fontSize: '24px', color: 'rgba(152, 162, 179, 1)' }} />
          <LinkedinFilled style={{ fontSize: '24px', color: 'rgba(152, 162, 179, 1)' }} />
          <FacebookFilled style={{ fontSize: '24px', color: 'rgba(152, 162, 179, 1)' }} />
          <GithubFilled style={{ fontSize: '24px', color: 'rgba(152, 162, 179, 1)' }} />
          <PeaceSignIcon />
          <BasketBallIcon />
        </Flex>
      </Flex>
      <hr style={{ color: '#475467' }} />
      <div className="mt-32">
        <Text className="text-lg-400" style={{ color: '#98A2B3' }}>© 2024 All Good People. All rights reserved.</Text>
      </div>
    </Footer>
  )
}

export default LandingFooter
import React, { useState } from "react"
import {
  Slider,
  InputNumber,
  Checkbox,
  Button,
  DatePicker,
  Row,
  Col,
  Flex,
  Typography,
  Divider,
  Space
} from "antd"
import "antd/dist/reset.css"

const { Title } = Typography

const defaultFn = () => ""

const FilterComponent = ({
  filters = {},
  setFilters = defaultFn,
  onFilterApply = defaultFn,
  setQueryFilters = defaultFn
}) => {
  const onStatusChange = event => {
    setFilters(prevFilters => ({
      ...prevFilters,
      status: event.target.name
    }))
  }

  const onRateChange = value => {
    setFilters(prevFilters => ({
      ...prevFilters,
      minHourlyRate: value[0],
      maxHourlyRate: value[1]
    }))
  }

  const onDateChange = dates => {
    setFilters(prevFilters => ({
      ...prevFilters,
      startDate: dates ? dates[0] : null,
      endDate: dates ? dates[1] : null
    }))
  }

  const clearFilters = () => {
    setFilters({
      minHourlyRate: null,
      maxHourlyRate: null,
      startDate: null,
      endDate: null,
      status: null
    })
    setQueryFilters({
      hourly_rate_max: null,
      hourly_rate_min: null,
      start_date: null,
      end_date: null,
      status: null
    })
  }

  return (
    <Flex vertical>
      <div className="px-4 pt-3 pb-2">
        <Title level={5}>Filters</Title>
      </div>
      <div className="px-4 py-3">
        <Row>
          <Col span={24}>
            <span>Hourly Rate</span>
          </Col>
          <Col span={12}>
            <Slider
              range
              min={0}
              max={100}
              value={[filters.minHourlyRate, filters.maxHourlyRate]}
              onChange={onRateChange}
              style={{ width: "90%" }}
            />
          </Col>
          <Col span={6}>
            <InputNumber
              min={0}
              max={100}
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value?.replace(/\$\s?|(,*)/g, "")}
              value={filters.minHourlyRate}
              onChange={value =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  minHourlyRate: value
                }))
              }
            />
          </Col>
          {/* <Col span={3}>
            <Divider />
          </Col> */}
          <Col span={6}>
            <InputNumber
              min={0}
              max={100}
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value?.replace(/\$\s?|(,*)/g, "")}
              value={filters?.maxHourlyRate}
              onChange={value =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  maxHourlyRate: value
                }))
              }
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <span>Duration</span>
          </Col>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              value={[filters.startDate, filters.endDate]}
              onChange={onDateChange}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <span>Status</span>
          </Col>
          <Col span={24}>
            <Flex vertical gap="small">
              <Checkbox
                name="active"
                checked={filters.status === "active"}
                onChange={onStatusChange}
              >
                Active
              </Checkbox>
              <Checkbox
                name="pending"
                checked={filters.status === "pending"}
                onChange={onStatusChange}
              >
                Pending
              </Checkbox>
              <Checkbox
                name="completed"
                checked={filters.status === "completed"}
                onChange={onStatusChange}
              >
                Completed
              </Checkbox>
              <Checkbox
                name="cancelled"
                checked={filters.status === "cancelled"}
                onChange={onStatusChange}
              >
                Cancelled
              </Checkbox>
            </Flex>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginTop: "16px" }}>
          <Button type="link" onClick={clearFilters}>
            Clear all
          </Button>
          <div>
            {/* <Button style={{ marginRight: "10px" }}>Cancel</Button> */}
            <Button type="primary" onClick={onFilterApply}>
              Apply
            </Button>
          </div>
        </Row>
      </div>
    </Flex>
  )
}

export default FilterComponent

import React, { useEffect, useState } from "react"
import {
  Avatar,
  Button,
  Checkbox,
  Empty,
  Flex,
  Modal,
  Spin,
  Typography
} from "antd"
import CustomInput from "../../../../components/customInput/CustomInput"

import {
  ExclamationCircleOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined
} from "@ant-design/icons"
import { useLeaveCommunityMutation } from "../../../../api/apiSlices/communityApiSlice"
import { useNavigate } from "react-router-dom"
import { useGetAllUserCommunitiesQuery } from "../../../../api/apiSlices/communityApiSlice"
import { Routes } from "../../../../constants/routes"
import "./styles.scss"

const { Text } = Typography

const AttachCommunities = ({
  setAttachedCommunitiesIds,
  attachedCommunitiesIds,
  open,
  onClose
}) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [communities, setCommunities] = useState([])
  const [selectedCommunities, setSelectedCommunities] = useState(
    attachedCommunitiesIds || []
  )

  const [modal, contextHolder] = Modal.useModal()

  const {
    data: { results, next } = { results: [], next: null },
    refetch: refetchCommunities,
    isLoading
  } = useGetAllUserCommunitiesQuery({
    searchText: search,
    page: currentPage
  })

  useEffect(() => {
    if (results.length) {
      setCommunities([...communities, ...results])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const [leaveCommunity] = useLeaveCommunityMutation()

  const handleLeaveCommunity = async id => {
    await leaveCommunity(id)
      .unwrap()
      .then(() => {
        refetchCommunities()
      })
  }

  const showRemoveModal = id => {
    modal.confirm({
      title: "Leave Community",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to leave this community? This action cannot be undone.",
      okText: "Leave",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () => handleLeaveCommunity(id),
      centered: true
    })
  }

  const onConfirm = () => {
    setAttachedCommunitiesIds(selectedCommunities)
    onClose()
  }

  const onChangeSearch = e => {
    setCommunities([])
    setCurrentPage(1)
    setSearch(e.target.value)
  }

  const onUpdateCheckedStatus = (event, communityId) => {
    if (event.target.checked)
      setSelectedCommunities([...selectedCommunities, communityId])
    else
      setSelectedCommunities(
        selectedCommunities.filter(id => id !== communityId)
      )
  }
  return (
    <>
      <Modal
        open={open}
        centered
        title="Add Communities"
        onCancel={onClose}
        onOk={onConfirm}
        okText="Done"
        cancelText="Cancel"
      >
        <Spin spinning={isLoading}>
          <Flex vertical gap="middle" className="attach-communities">
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
              value={search}
              onChange={onChangeSearch}
            />
            {!isLoading && communities?.length === 0 && (
              <Flex justify="center" align="center" vertical>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            )}
            {communities?.map((community, index) => {
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex
                    gap="small"
                    onClick={() =>
                      navigate(
                        Routes.COMMUNITY.replace(":id", community.community_id)
                      )
                    }
                    className="cursor-pointer"
                  >
                    <Avatar
                      size="small"
                      icon={<UsergroupDeleteOutlined />}
                      src={community?.display_image?.url}
                    />
                    <Text>{community.name}</Text>
                  </Flex>
                  <Checkbox
                    checked={selectedCommunities.includes(
                      community.community_id
                    )}
                    onChange={checked =>
                      onUpdateCheckedStatus(checked, community.community_id)
                    }
                  />
                </Flex>
              )
            })}
            {next && (
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                type="text"
                style={{
                  width: "min-content",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                Load more
              </Button>
            )}
          </Flex>
          {contextHolder}
        </Spin>
      </Modal>
    </>
  )
}

export default AttachCommunities

import { Typography } from "antd"
import "./styles.scss"

const { Text } = Typography

const SettingsSection = ({ title, description = "", children }) => {
  return (
    <div className="settingsCard">
      <div className="settingsInfo">
        <Text className="title">{title}</Text>
        <Text className="description">{description}</Text>
      </div>
      <div>{children}</div>
    </div>
  )
}

export default SettingsSection

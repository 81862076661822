import { useEffect, useState } from "react"
import { useMyJoinedCommunitiesQuery } from "../api/apiSlices/communityApiSlice"

export const useIsPartOfCommunity = communityId => {
  const [isJoined, setIsJoined] = useState(false)
  const {
    data: joinedCommunities,
    isLoading,
    refetch
  } = useMyJoinedCommunitiesQuery()

  useEffect(() => {
    if (isLoading) return
    if (joinedCommunities?.results.length && communityId) {
      const isPartOf = joinedCommunities?.results
        .map(community => community.community_id)
        .includes(parseInt(communityId))
      if (isPartOf) setIsJoined(true)
    }
  }, [joinedCommunities?.results, communityId, isLoading])

  return [isJoined, refetch, isLoading]
}

import { Button, Checkbox, Flex, Spin, TimePicker } from "antd"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./styles.scss"
import { openNotification } from "../../../util/openNotifications"
import { useAddRecurringAvailabilityMutation } from "../../../api/apiSlices/workerApiSlice"
import { useMemo } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

const convertUTCToLocal = utcTime => dayjs.utc(utcTime).local()
const convertLocalToUTC = localTime => localTime.utc().format()

function combineDateWithTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(":").map(Number)
  const now = dayjs().utc()
  const dateWithTime = now
    .set("hour", hours)
    .set("minute", minutes)
    .set("second", seconds)
  return dateWithTime
}

function isTimeBefore(startTime, endTime) {
  if (!startTime || !endTime) return false // If any time is missing, return false

  const startHours = dayjs(startTime).hour()
  const startMinutes = dayjs(endTime).minute()

  const endHours = dayjs(endTime).hour()
  const endMinutes = dayjs(endTime).minute()

  // Compare only the time parts
  if (startHours < endHours) return true
  if (startHours === endHours && startMinutes < endMinutes) return true

  return false
}

const validationSchema = Yup.object().shape({
  days: Yup.array().of(
    Yup.object().shape({
      selected: Yup.boolean(),
      start_time: Yup.string().when("selected", {
        is: true,
        then: Yup.string().required("Start time is required")
      }),
      end_time: Yup.string()
        .when("selected", {
          is: true,
          then: Yup.string().required("End time is required")
        })
        .when("start_time", (startTime, schema) => {
          if (startTime) {
            return schema.test(
              "is-after",
              "End time must be after start time",
              function (endTime) {
                return isTimeBefore(startTime, endTime)
              }
            )
          }
          return schema
        })
    })
  )
})

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

export default function RecurringAvailabilityForm({ refetch, data }) {
  const [addRecurringAvailability, { isLoading }] =
    useAddRecurringAvailabilityMutation()

  const initialData = useMemo(() => {
    if (data?.weekly_availability.length === 0)
      return {
        days: daysOfWeek.map(day => ({
          day_of_week: day,
          selected: false,
          start_time: "",
          end_time: ""
        }))
      }
    return {
      days: daysOfWeek.map(day => {
        const savedDay = data?.weekly_availability.find(
          iteratedDay => iteratedDay.day_of_week === day
        )
        if (savedDay) {
          return {
            ...savedDay,
            selected: true,
            start_time: combineDateWithTime(savedDay.start_time),
            end_time: combineDateWithTime(savedDay.end_time)
          }
        }
        return {
          day_of_week: day,
          selected: false,
          start_time: "",
          end_time: ""
        }
      })
    }
  }, [data])

  const { values, handleSubmit, setValues, handleChange, errors, touched } =
    useFormik({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: values => {
        const data = values.days
          .filter(day => day.start_time && day.selected)
          .map(day => {
            return {
              day_of_week: day.day_of_week,
              start_time: day.start_time,
              end_time: day.end_time
            }
          })
        if (data.length === 0) {
          openNotification({ type: "info", message: "Nothing to update" })
          return
        }
        addRecurringAvailability(data)
          .unwrap()
          .then(() => {
            openNotification({
              type: "success",
              message: "Saved successfully!"
            })
            refetch()
          })
          .catch(error => {
            openNotification({ type: "error", message: error?.message })
          })
      }
    })

  return (
    <Flex wrap gap="10px" justify="space-between" className="mt-3">
      <Button
        className="saveSchedule"
        onClick={handleSubmit}
        type="primary"
        disabled={isLoading}
      >
        Save Schedule
      </Button>
      {values.days.map((day, index) => (
        <Flex vertical style={{ width: "calc(50% - 10px)" }}>
          <Checkbox
            className="mb-2 "
            name={`days[${index}].selected`}
            checked={day.selected}
            onChange={handleChange}
          >
            <span style={{ textTransform: "capitalize" }}>
              {day.day_of_week.slice(0, 3)}
            </span>
          </Checkbox>
          {day.selected && (
            <Flex gap="8px">
              <Flex vertical>
                <TimePicker
                  allowClear={false}
                  name={`days[${index}].start_time`}
                  placeholder="Select start time"
                  value={
                    day.start_time ? convertUTCToLocal(day.start_time) : null
                  }
                  onChange={startTime => {
                    setValues({
                      ...values,
                      days: values.days.map(iteratedDay => {
                        if (iteratedDay.day_of_week === day.day_of_week) {
                          return {
                            ...iteratedDay,
                            start_time: convertLocalToUTC(startTime)
                          }
                        }
                        return iteratedDay
                      })
                    })
                  }}
                  format="h:mm a"
                />
                {touched?.days?.[index]?.start_time &&
                  errors?.days?.[index]?.start_time && (
                    <p className="Input_errorMessage">
                      {errors.days[index].start_time}
                    </p>
                  )}
              </Flex>
              <Flex vertical>
                <TimePicker
                  allowClear={false}
                  name={`days[${index}].end_time`}
                  placeholder="Select end time"
                  value={day.end_time ? convertUTCToLocal(day.end_time) : null}
                  onChange={endTime => {
                    setValues({
                      ...values,
                      days: values.days.map(iteratedDay => {
                        if (iteratedDay.day_of_week === day.day_of_week) {
                          return {
                            ...iteratedDay,
                            end_time: convertLocalToUTC(endTime)
                          }
                        }
                        return iteratedDay
                      })
                    })
                  }}
                  format="h:mm a"
                />
                {touched?.days?.[index]?.end_time &&
                  errors?.days?.[index]?.end_time && (
                    <p className="Input_errorMessage">
                      {errors.days[index].end_time}
                    </p>
                  )}
              </Flex>
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  )
}

import React from "react";
import { Tag } from "antd";

const AntDesignCheckable = ({ 
  tagText = "Default Tag",
  isSelected, // New prop to determine if the tag is selected
  onClick, 
  defaultBgColor = "rgba(230, 244, 255, 0.5)", 
  defaultTextColor = "rgba(36, 134, 153, 1)", 
  selectedBgColor = "rgba(61, 194, 220, 1)", 
  selectedTextColor = "white" 
}) => {
  return (
    <Tag.CheckableTag
      checked={isSelected} // Use the isSelected prop to control checked state
      onChange={onClick} // Call the onClick function when the tag is clicked
      style={{
        backgroundColor: isSelected ? selectedBgColor : defaultBgColor,
        color: isSelected ? selectedTextColor : defaultTextColor,
        fontSize: "12px",
        fontWeight: 500,
        borderRadius: "16px",
        cursor: "pointer",
      }}
    >
      {tagText}
    </Tag.CheckableTag>
  );
};

export default AntDesignCheckable;

import { Avatar, Button, Drawer, Empty, Flex, Typography } from "antd"
import {
  useGetNotificationsQuery,
  useMarkAllNotifcationsAsReadMutation
} from "../../api/apiSlices/userApiSlice"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../constants/routes"
import {
  useAcceptConnectionRequestMutation,
  useRejectConnectionRequestMutation
} from "../../api/apiSlices/socialMediaApiSlice"
import { openNotification } from "../../util/openNotifications"
import {
  useAcceptCommunityJoinReqMutation,
  useRejectCommunityJoinReqMutation
} from "../../api/apiSlices/communityApiSlice"
import { useEffect } from "react"
import { dispatch } from "../../redux/store"
import { apiSlice } from "../../api/apiSlice"

const { Text, Title } = Typography

export default function Notifications({ open, onClose }) {
  const navigate = useNavigate()
  const {
    data: { notifications = [], unread_count = 0 },
    refetch: refetchNotifications
  } = useGetNotificationsQuery()
  const [markAllNotificationsAsRead] = useMarkAllNotifcationsAsReadMutation()
  const [acceptConnectionRequest] = useAcceptConnectionRequestMutation()
  const [rejectConnectionRequest] = useRejectConnectionRequestMutation()
  const [acceptCommunityJoinReq] = useAcceptCommunityJoinReqMutation()
  const [rejectCommunityJoinReq] = useRejectCommunityJoinReqMutation()

  useEffect(() => {
    if (unread_count) {
      markAllNotificationsAsRead().then(() => {
        dispatch(
          apiSlice.util.updateQueryData(
            "getNotifications",
            undefined,
            draft => {
              draft.unread_count = 0
            }
          )
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unread_count])

  const onAcceptConnectionReq = async id => {
    await acceptConnectionRequest(id)
      .unwrap()
      .then(() => {
        refetchNotifications()
        openNotification({
          type: "success",
          message: "Connection request accepted"
        })
      })
  }

  const onRejectConnectionReq = async id => {
    await rejectConnectionRequest(id)
      .unwrap()
      .then(() => {
        refetchNotifications()
        openNotification({
          type: "success",
          message: "Connection request rejected"
        })
      })
  }

  const onAcceptCommunityReq = async id => {
    await acceptCommunityJoinReq(id)
      .unwrap()
      .then(() => {
        refetchNotifications()
        openNotification({
          type: "success",
          message: "Community invite request accepted"
        })
      })
  }

  const onRejectCommunityReq = async id => {
    const body = { invitation_id: id, id: "null" }
    await rejectCommunityJoinReq(body)
      .unwrap()
      .then(() => {
        refetchNotifications()
        openNotification({
          type: "success",
          message: "Connection invite request rejected"
        })
      })
  }

  const mappedNotifications = notifications.map(notification => {
    if (notification.notification_type === "user_connection_request") {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: "1px solid  #D9D9D9", paddingBottom: "12px" }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose()
              navigate(Routes.PROFILE.replace(":id", notification.object_id))
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details.sender}
              </Title>
              <Text>Requested to connect with you</Text>
            </Flex>
          </Flex>
          <Flex gap={8} style={{ marginLeft: "50px" }}>
            <Button
              type="primary"
              primary
              onClick={() => {
                onAcceptConnectionReq(notification.object_id)
              }}
            >
              Accept
            </Button>
            <Button
              type="default"
              primary
              onClick={() => {
                onRejectConnectionReq(notification.object_id)
              }}
            >
              Reject
            </Button>
          </Flex>
        </Flex>
      )
    } else if (notification.notification_type === "community_join_request") {
      return (
        <Flex
          gap="middle"
          vertical
          style={{ borderBottom: "1px solid  #D9D9D9", paddingBottom: "12px" }}
        >
          <Flex
            gap="middle"
            align="center"
            onClick={() => {
              onClose()
              navigate(Routes.COMMUNITY.replace(":id", notification.object_id))
            }}
            className="cursor-pointer"
          >
            <Avatar
              size={40}
              src={notification.content_object_details?.display_image?.url}
            />
            <Flex vertical>
              <Title level={5}>
                {notification.content_object_details.sender}
              </Title>
              <Text>{notification.message}</Text>
            </Flex>
          </Flex>
          <Flex gap={8} style={{ marginLeft: "50px" }}>
            <Button
              type="primary"
              primary
              onClick={() => {
                onAcceptCommunityReq(notification.object_id)
              }}
            >
              Accept
            </Button>
            <Button
              type="default"
              primary
              onClick={() => {
                onRejectCommunityReq(notification.object_id)
              }}
            >
              Reject
            </Button>
          </Flex>
        </Flex>
      )
    }
    return <></>
  })
  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title="Notifications"
      width={380}
    >
      <Flex vertical gap={12}>
        {mappedNotifications.length === 0 && (
          <Flex
            justify="center"
            align="center"
            vertical
            style={{ height: "100%", marginTop: "100px" }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Flex>
        )}
        {mappedNotifications}
      </Flex>
    </Drawer>
  )
}

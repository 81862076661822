import { Input } from "antd"

const defaultFn = () => ""

const CustomTextArea = props => {
  const {
    name = "",
    inputwd = "",
    labelClass = "",
    label = "",
    errorMessage = "",
    placeholder = "",
    value = "",
    description="",
    onChange = defaultFn,
    onBlur = defaultFn,
    inputProps = {}
  } = props

  return (
    <>
      <div className={inputwd}>
        {label && (
          <label className={`${labelClass} Input_label`}>{label}</label>
        )}
         {description && <p className='text-22-400' style={{marginBottom:'4px' , color:'#00000073'}}>{description}</p>} 
        <Input.TextArea
          className="Input"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          onBlur={onBlur}
          autoSize={{
            minRows: 4,
            maxRows: 6
          }}
          {...inputProps}
        />
        {errorMessage && <p className="Input_errorMessage">{errorMessage}</p>}
      </div>
    </>
  )
}
export default CustomTextArea

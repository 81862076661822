import React from "react"
import { Flex, Layout, Avatar, Typography, Dropdown } from "antd"
import appLogo from "../../assets/common/logo.png"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"
import { UserOutlined } from "@ant-design/icons"
import "./styles.scss"
import { removeJWTToken, removeRefreshToken, removeUser } from "../../api/auth"

const { Text } = Typography
const { Header } = Layout

const DonationHeaderNav = () => {
  const { navigateTo } = useRouterNavigation()

  const handleLogOut = () => {
    removeJWTToken()
    removeRefreshToken()
    removeUser()
    navigateTo(Routes.LOGIN)
  }

  const items = [
    {
      label: (
        <Text onClick={() => navigateTo(Routes.SELECT_PLATFORM)}>
          Switch Services
        </Text>
      ),
      key: "0"
    },
    {
      label: <Text onClick={handleLogOut}>Logout</Text>,
      key: "1"
    }
  ]

  return (
    <Header className="HeaderWrapper">
      <div className="HeaderBounds">
        <Flex justify="space-between" align="center">
          <Flex gap={48} align="center">
            <div className="AppLogoWrap cursor-pointer">
              <img
                src={appLogo}
                alt="app logo"
                onClick={() => navigateTo(Routes.DONATION)}
              />
            </div>
          </Flex>
          <Flex gap="middle" align="center">
            <Dropdown
              menu={{
                items
              }}
              trigger={["click"]}
              overlayStyle={{ width: "165px" }}
            >
              <a
                style={{ lineHeight: "32px" }}
                onClick={e => e.preventDefault()}
              >
                <Avatar
                  size={32}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#3DC2DC" }}
                />
              </a>
            </Dropdown>
          </Flex>
        </Flex>
      </div>
    </Header>
  )
}

export default DonationHeaderNav

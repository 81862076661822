import { UserOutlined } from "@ant-design/icons"
import { Avatar, Typography, Flex, Button, Col, Spin, Upload } from "antd"
import Title from "antd/es/typography/Title"
import styles from "./profile.module.scss"
import UserConnectionManagment from "./components/user-connection/UserConnectionManagment"
import PostCard from "../social-media/components/postCard/PostCard"
import SuggestionsList from "../social-media/components/suggestionsList/suggestionsList"

import {
  useAddCommentToPostMutation,
  useCreateSocialUserChatMutation,
  useGetSocialMediaProfileQuery,
  useGetUserPostsQuery,
  useTogglePostLikeMutation,
  useUpdateSocialMediaProfileMutation
} from "../../api/apiSlices/socialMediaApiSlice"

import {
  AddUserIcon,
  BubbleChatIcon,
  LockIcon,
  Yellow28EditIcon
} from "../../assets/rawSvgs"
import { useParams } from "react-router-dom"
import { getUser } from "../../api/auth"
import { openNotification } from "../../util/openNotifications"
import { useAddMediaMutation } from "../../api/apiSlices/settingsApiSlice"
import useRouterNavigation from "../../hooks/useNavigate"
import { Routes } from "../../constants/routes"

const { Text } = Typography

const Profile = () => {
  const currentUser = getUser()
  let { id: userId } = useParams()

  const { navigateTo } = useRouterNavigation()

  const isCurrentUserProfile = currentUser.id === parseInt(userId)
  const [addMedia] = useAddMediaMutation()
  const [createChat, { isLoading: isChatLoading }] =
    useCreateSocialUserChatMutation()

  const { data: userPosts } = useGetUserPostsQuery()

  const {
    data: userProfile,
    isLoading: isProfileLoading,
    refetch: refetchUserProfile
  } = useGetSocialMediaProfileQuery(userId)

  const [togglePostLike] = useTogglePostLikeMutation()
  const [updateProfile] = useUpdateSocialMediaProfileMutation()

  const [addCommentToPost, { isLoading: isAddCommentToPostLoading }] =
    useAddCommentToPostMutation()

  const handleCreateChat = async () => {
    try {
      const payload = {
        member_id: userId
      }
      const chat = await createChat(payload).unwrap()
      navigateTo(`${Routes.CHAT}?channel_id=${chat?.channel_id}`)
      openNotification({
        type: "success",
        message: "Chat created successfully!"
      })
    } catch (error) {
      openNotification({
        type: "error",
        message:
          error?.message ||
          error?.errors ||
          "Unexpected error occurred. Please try again."
      })
    }
  }

  const handleUpload = async ({ file }) => {
    const allowedTypes = ["image/jpeg", "image/png"]

    if (!allowedTypes.includes(file.type)) {
      openNotification({
        type: "error",
        message: "Inavlid file type. Please upload an image file(JPEG or PNG) "
      })
      return
    }

    const formData = new FormData()
    formData.append("file", file)
    try {
      const data = await addMedia(formData).unwrap()
      updateProfile({ profile_picture: data.object_key })
        .unwrap()
        .then(() => {
          refetchUserProfile()
        })
    } catch (error) {
      openNotification({ type: "error", message: error?.message })
    }
  }

  if (isProfileLoading || !userProfile) {
    return (
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Spin spinning />
      </Flex>
    )
  }

  return (
    <div className={styles.ProfileContainer}>
      <Flex justify="space-between" align="start">
        <Flex gap="large" style={{ marginBottom: "16px" }}>
          <div className={styles.editProfileImage}>
            <Avatar
              size={100}
              src={userProfile?.profile_picture?.url}
              icon={!userProfile?.profile_picture?.url && <UserOutlined />}
              style={{ backgroundColor: "#3DC2DC" }}
            />
            {isCurrentUserProfile && (
              <span className={styles.editProfileIcon}>
                <Upload
                  customRequest={handleUpload}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Yellow28EditIcon />
                </Upload>
              </span>
            )}
          </div>
          <Flex vertical gap="large">
            <div>
              <Title level={5}>{userProfile.name}</Title>
              <Flex align="center" gap={12}>
                <Text type="secondary" className="text-lg-400">
                  @{userProfile.username}
                </Text>
                {userProfile.age && (
                  <>
                    <div className="dot dot_small"></div>
                    <Text type="secondary" className="text-lg-400">
                      Age: {userProfile.age}
                    </Text>
                  </>
                )}

                {userProfile.gender && (
                  <>
                    <div className="dot dot_small" />
                    <Text type="secondary" className="text-lg-400">
                      {userProfile.gender}
                    </Text>
                  </>
                )}
              </Flex>
            </div>
            {!isProfileLoading && (
              <UserConnectionManagment
                friendsCount={userProfile.friends_list_count}
              />
            )}
          </Flex>
        </Flex>
        {!isCurrentUserProfile && (
          <Flex gap={12} align="center">
            <Button
              htmlType="text"
              // type="primary"
              size="large"
              icon={<AddUserIcon />}
            >
              Add Friend
            </Button>
            <Button
              htmlType="text"
              size="large"
              onClick={handleCreateChat}
              icon={<BubbleChatIcon color="rgba(0, 0, 0, 0.88)" />}
              disabled={isChatLoading}
            >
              Message
            </Button>
          </Flex>
        )}
      </Flex>
      <Flex vertical>
        {userProfile.bio && (
          <Text style={{ fontSize: "16px" }}>{userProfile.bio}</Text>
        )}
      </Flex>
      <div className={styles.profileScreenScroll}>
        {!isCurrentUserProfile && userProfile.is_profile_locked && (
          <div className={styles.lockCard}>
            <LockIcon />
            <Text className="text-lg-500">
              {userProfile.username} Locked her Profile
            </Text>
          </div>
        )}
        <Flex gap={24} justify="space-between">
          <Col span={16}>
            {(isCurrentUserProfile ||
              (!isCurrentUserProfile && !userProfile.is_profile_locked)) && (
              <>
                {userPosts?.posts?.map(feed => {
                  return (
                    <PostCard
                      index={feed?.post_id}
                      attachments={feed?.attachments}
                      author={feed?.author}
                      comments={feed?.comments}
                      comments_count={feed?.comments_count}
                      comments_enabled={feed?.comments_enabled}
                      content={feed?.content}
                      created_at={feed?.created_at}
                      likes_count={feed?.likes_count}
                      post_id={feed?.post_id}
                      likes_enabled={feed?.likes_enabled}
                      user_has_liked={feed?.user_has_liked}
                      likes={feed?.likes}
                      togglePostLike={togglePostLike}
                      addCommentToPost={addCommentToPost}
                      isAddCommentToPostLoading={isAddCommentToPostLoading}
                    />
                  )
                })}
              </>
            )}
          </Col>
          <Col span={6}>
            <SuggestionsList />
          </Col>
        </Flex>
      </div>
    </div>
  )
}

export default Profile

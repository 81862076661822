import React from "react"
import { Avatar, Button, Empty, Flex, Modal, Spin, Typography } from "antd"

import { UserOutlined } from "@ant-design/icons"
import {
  useAcceptCommunityJoinReqMutation,
  useRejectCommunityJoinReqMutation,
  useViewCommunityInvitationsQuery
} from "../../../../api/apiSlices/communityApiSlice"

const { Text } = Typography

export const ViewInvitations = ({ open = false, onClose, communityId }) => {
  const {
    data: communityJoinRequests,
    isLoading: isReqLoading,
    refetch
  } = useViewCommunityInvitationsQuery(communityId)

  const [removeJoinRequest, { isLoading: isRemoving }] =
    useRejectCommunityJoinReqMutation()

  const [acceptJoinRequest, { isLoading: isAccepting }] =
    useAcceptCommunityJoinReqMutation()

  const acceptRequest = async id => {
    await acceptJoinRequest(id)
      .unwrap()
      .then(() => {
        refetch()
      })
  }

  const deleteRequest = async invitationId => {
    const body = { invitation_id: invitationId, id: communityId }
    await removeJoinRequest(body)
      .unwrap()
      .then(() => {
        refetch()
      })
  }

  return (
    <>
      <Modal
        open={open}
        centered
        title="Community Join Requests"
        onCancel={onClose}
        footer={null}
      >
        <div className="feedbackModal">
          <Spin spinning={isReqLoading || isAccepting || isRemoving}>
            <Flex vertical gap="middle">
              {!isReqLoading && communityJoinRequests.length === 0 && (
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ height: "100%" }}
                >
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Flex>
              )}

              {communityJoinRequests?.map((req, index) => {
                return (
                  <Flex key={index} justify="space-between" align="center">
                    <Flex gap="small">
                      <Avatar size="small" icon={<UserOutlined />} />
                      <Text>{req.profile.username || "Anonymous"}</Text>
                    </Flex>
                    <Flex gap="small">
                      <Button
                        onClick={() => deleteRequest(req.invitation_id)}
                        danger
                        variant="outlined"
                      >
                        Reject
                      </Button>
                      <Button onClick={() => acceptRequest(req.invitation_id)}>
                        Accept
                      </Button>
                    </Flex>
                  </Flex>
                )
              })}
            </Flex>
          </Spin>
        </div>
      </Modal>
    </>
  )
}

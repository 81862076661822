import React, { useState } from "react"
import { Avatar, Button, Flex, Modal, Spin, Typography } from "antd"

import CustomInput from "../../../../components/customInput/CustomInput"

import { useDebounce } from "../../../../hooks/useDebounce"

import {
  ExclamationCircleOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons"
import {
  useGetCommunityMembersQuery,
  useRemoveCommunityMemberMutation
} from "../../../../api/apiSlices/communityApiSlice"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../../../constants/routes"

const { Text } = Typography

const Members = ({ memberCount = 0, communityId, isAdmin = false }) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()

  const debouncedSearchTerm = useDebounce(search, 500)

  const [modal, contextHolder] = Modal.useModal()

  const {
    data: members,
    refetch,
    isLoading
  } = useGetCommunityMembersQuery({
    searchTerm: debouncedSearchTerm,
    id: communityId
  })

  const [removeCommunityMember] = useRemoveCommunityMemberMutation()

  const showModal = () => {
    if (memberCount === 0) return
    setOpen(true)
  }

  const handleRemoveMember = async userId => {
    await removeCommunityMember({ communityId, userId })
    refetch()
  }

  const showRemoveModal = id => {
    modal.confirm({
      title: "Remove Member",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to remove this member? This action cannot be undone.",
      okText: "Remove",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      onOk: () => handleRemoveMember(id),
      centered: true
    })
  }

  const filteredMembers =
    members?.filter(member => member.username.includes(debouncedSearchTerm)) ||
    []
  return (
    <>
      <Modal
        open={open}
        centered
        title="Members"
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Spin spinning={isLoading}>
          <Flex vertical gap="middle">
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {filteredMembers?.map((member, index) => {
              return (
                <Flex key={index} justify="space-between" align="center">
                  <Flex
                    gap="small"
                    onClick={() =>
                      navigate(Routes.PROFILE.replace(":id", member.user_id))
                    }
                    className="cursor-pointer"
                  >
                    <Avatar
                      size="small"
                      icon={<UserOutlined />}
                      src={member.profile_picture?.url}
                    />
                    <Text>{member.username || "Anonymous"}</Text>
                  </Flex>
                  <Flex gap="small">
                    {isAdmin && (
                      <Button onClick={() => showRemoveModal(member.user_id)}>
                        Remove
                      </Button>
                    )}
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </Spin>
      </Modal>
      <Text onClick={showModal} underline className="text-lg-500">
        <Text className={memberCount && "cursor-pointer"}>
          {memberCount} Members
        </Text>
      </Text>
      {contextHolder}
    </>
  )
}

export default Members

import { main_api as axiosInstance } from "../axios_helper"

import {
  ADD_POST_COMMENT,
  COMMUNITY_FEED,
  REMOVE_COMMUNITY_POST,
  TOGGLE_POST_LIKE,
  COMMUNITIES_FEED
} from "../../constants/endpoints"

const handleApiResponse = async promise => {
  try {
    const response = await promise
    return { data: response.data, error: null }
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message
    }
  }
}

export const fetchFeed = async ({ feedType, feedId, page }) => {
  let url = null
  if (feedType === "communities") {
    url = COMMUNITIES_FEED + `?page=${page}`
  } else {
    url = COMMUNITY_FEED.replace(":id", feedId) + `?page=${page}`
  }
  return handleApiResponse(axiosInstance.get(url))
}

export const likePost = async postId => {
  const url = TOGGLE_POST_LIKE.replace(":postId", postId)
  return handleApiResponse(axiosInstance.post(url))
}

export const commentOnPost = async ({ postId, content }) => {
  const url = ADD_POST_COMMENT.replace(":postId", postId)
  return handleApiResponse(axiosInstance.post(url, { content }))
}

export const rejectCommunityPost = async ({ postId, communityId }) => {
  const url = REMOVE_COMMUNITY_POST.replace(":id", postId)
  return handleApiResponse(axiosInstance.delete(url))
}

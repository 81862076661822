import React, { useEffect } from "react";
import CustomInput from "../../../components/customInput/CustomInput";
import CustomTextArea from "../../../components/customInput/CustomTextArea";
import Header from "../../user-experience/components/Header";
import { Button, Flex, Space, Spin, Typography } from "antd";
//import "./styles.scss";
import styles from './styles.module.scss'

const { Title, Paragraph } = Typography;

const DESCRIPTION =
  "Provide a clear and concise job title, followed by a detailed description of the responsibilities and expectations for the role";

const CreateProfile = ({
  initialValues,       
  errors,              
  touched,             
  handleChange,        
  handleBlur,          
  onNextClick, 
  isValid       
}) => {
  
  
  return (
    <>
         <div className={styles.wrap}>
          <Header title="Job Information" description={DESCRIPTION} />
          </div> 
          
          <form>
            
            <CustomInput
              name="professional_role"
              label={<span className={styles.customLabel}>Job Title</span>}
              inputwd="w-100 mb-3"
              placeholder="Enter role here"
              className="mb-3"
              value={initialValues?.professional_role}
              onChange={handleChange}
              errorMessage={
                touched["professional_role"] && errors?.professional_role
              }
              onBlur={handleBlur}
              inputProps={{
                status: touched["professional_role"] && errors?.professional_role
                  ? "error"
                  : undefined
              }}
            />

              <Title level={5} className={styles.exampleTitles}>
                Example Titles
              </Title>
              <Paragraph>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px', color: 'rgba(0, 0, 0, 0.45)' }}>
                  <li>Expert Mobile App Developer Needed for Innovative Project</li>
                  <li>Experienced Graphic Designer Required for Branding Campaign</li>
                  <li>Seeking Skilled Content Writer for Tech Blog</li>
                </ul>
              </Paragraph>
                        
            <div styles={{marginTop:'100px', backgroundColor:'green'}}>
            <CustomTextArea
              name="description"
              label="Describe what you need"
              inputwd="w-100 mb-3"
              placeholder="Describe what you need"
              className="mb-3"
              value={initialValues?.description}
              onChange={handleChange}
              errorMessage={touched["description"] && errors?.description}
              onBlur={handleBlur}
              inputProps={{
                status: touched["description"] && errors?.description
                  ? "error"
                  : undefined
              }}
            />
            </div>
          </form>
         
        <Flex justify="flex-end"   style={{ marginTop: '200px' }}>
          <Button type="primary" onClick={onNextClick}
          >
            Next
          </Button>
        </Flex>
     
    </>
  );
};

export default CreateProfile;

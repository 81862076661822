import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { StreamChat } from "stream-chat"
import {
  Chat,
  Channel,
  ChannelList,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  Avatar
} from "stream-chat-react"

import { getQueryParams } from "../../util/getQueryParams"

import { getStreamToken, getUser, getUserId } from "../../api/auth"

import { ROLES } from "../../constants/roles"
import { CHANNEL_TYPES } from "../../constants/chat"

import "./layout.modules.css"

const apiKey = "7xg9zsjstaep"

const client = StreamChat.getInstance(apiKey)

const ChatManage = () => {
  const userId = getUserId()
  const user = getUser()
  const currentRole = getUser()?.role
  const streamToken = getStreamToken()

  const location = useLocation()

  const { channel_id } = getQueryParams(location.search)

  const handleChatFilter = role => {
    switch (role) {
      case ROLES.SOCIAL_USER:
        return CHANNEL_TYPES.SOCIAL_MEDIA
      case ROLES.EMPLOYER:
      case ROLES.WORKER:
        return CHANNEL_TYPES.MESSAGING
      default:
        return null
    }
  }

  const CustomChannelPreview = props => {
    const { channel, setActiveChannel } = props
    const otherMember = Object.values(channel.state.members).find(
      member => member.user.id !== userId
    )

    return (
      <div
        className="custom-channel-preview"
        onClick={() => setActiveChannel(channel)}
      >
        <Avatar
          image={otherMember?.user.image}
          name={otherMember?.user.name}
          size={40}
        />
        <span className="channel-preview-title">
          {otherMember?.user.name || "Chat"}
        </span>
      </div>
    )
  }

  useEffect(() => {
    const init = async () => {
      if (!userId || !streamToken) {
        console.error("User ID or Stream Token is missing.")
        return
      }

      try {
        await client.connectUser({ id: userId }, streamToken)
      } catch (error) {
        console.error("Error connecting user to Stream Chat:", error)
      }
    }

    init()
  }, [userId, streamToken])

  if (!client) return <div>Setting up client & connection...</div>
  const filters = {
    type: handleChatFilter(currentRole),
    members: { $in: [userId] }
  }
  const sort = [{ last_message_at: -1 }]
  return (
    <div className="chat-management-container">
      <Chat client={client}>
        <ChannelList
          filters={filters}
          sort={sort}
          showChannelSearch
          customActiveChannel={channel_id || ""}
          // Preview={CustomChannelPreview}
        />

        <Channel>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  )
}

export default ChatManage

import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
// slices

import authReducer from "./slices/auth"
import socialMediaReducer from "./slices/socialMedia"
import { apiSlice } from "../api/apiSlice"

const rolePersist = {
  key: "role-persist",
  storage,
  keyPrefix: "redux-",
  whitelist: ["role"]
}

const rootReducer = combineReducers({
  auth: persistReducer(rolePersist, authReducer),
  socialMedia: socialMediaReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export { rootReducer }

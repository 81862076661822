import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  role: ""
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserRole(state, action) {
      state.role = action.payload.role
    }
  }
})

export const { setUserRole } = slice.actions
// Reducer
export default slice.reducer

import { useEffect } from "react"
import { Button, Flex, Select, Spin } from "antd"
import Header from "./Header"
import "./styles.scss"

const suggestedSkills = [
  "Digital Marketing",
  "Google Ads",
  "Social Media Management",
  "Microsoft Excel",
  "Figma Expert",
  "Newsletter",
  "Content Strategy"
]

const Skills = ({
  initialValues,
  onNextClick = null,
  onPreviousClick = null,
  wrapperClassName = "",

  errors,
  touched,
  handleSubmit,
  handleBlur,
  setValues
}) => {
  const currentSkills = initialValues?.skills

  const filteredSkills = suggestedSkills.filter(
    skill => !currentSkills.includes(skill)
  )

  return (
    <>
      <div >
        <Header
          title="Required Characteristics "
          description="Search and select Characteristics or life experiences relevant to your job, such as personal backgrounds or specific abilities, to 
          match workers who meet your unique criteria."
        />
        <Flex vertical>
          <label className={`Input_label`}>Skills</label>
          <Select
            name="skills"
            mode="tags"
            value={currentSkills}
            placeholder="Enter skills here"
            onChange={value => setValues({ ...initialValues, skills: value.map(tag=>tag.trim()).filter(tag=>tag!=="") })}
            onDeselect={value => {
              setValues({
                ...initialValues,
                skills: currentSkills.filter(skill => skill !== value)
              })
            }}
            handleBlur={handleBlur}
          />
          {touched["skills"] && errors?.skills && (
            <p className="Input_errorMessage">{errors?.skills}</p>
          )}
        </Flex>
        <div className="suggestedSkills">
          <h6>Suggested Characteristics</h6>
          <Flex gap={8} wrap>
            {filteredSkills.map(skill => (
              <div
                key={skill}
                className="skill"
                onClick={() =>
                  setValues({
                    ...initialValues,
                    skills: [...currentSkills, skill]
                  })
                }
              >
                {skill}
              </div>
            ))}
          </Flex>
        </div>
      </div>
      {onNextClick && (
        <Flex justify="flex-end" gap="middle" style={{ marginTop: "310px" }}>
          <Button type="default" onClick={onPreviousClick}>
            Back
          </Button>
          <Button type="primary" onClick={onNextClick}>
            Next
          </Button>
        </Flex>
      )}
    </>
  )
}

export default Skills

import React, { useState } from "react"
import { Button, Modal, Flex, Select, Input, Radio } from "antd"
import { Typography } from "antd"
import { PayPalIcon, PiggyBank, StripeIcon } from "../../assets/rawSvgs"
import DonationSuggestion from "./DonationSuggestion"

const { Title, Text, Link } = Typography
const { TextArea } = Input

const nonProfitOrganizationsStripe = [
  {
    value: "https://buy.stripe.com/test_14kcMMd7y1jaePu7ss",
    label: "All Good People"
  },
  {
    value: "https://buy.stripe.com/test_aEU9ECaHO1xP90c5kk",
    label: "We help all"
  }
]

const nonProfitOrganizationsPaypal = [
  {
    value: "https://www.paypal.com/US/fundraiser/charity/10371",
    label: "Boys and Girls Clubs of America"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/1328365",
    label: "Planned Parenthood"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/9261",
    label: "Habitat for Humanity"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/3843",
    label: "American Red Cross"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/1327055",
    label: "Feeding America's Children"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/194454",
    label: "YMCA of USA"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/1378373",
    label: "United Way worldwide"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/21041",
    label: "Make a wish foundation"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/1335689",
    label: "Teach for America"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/12854",
    label: "American Cancer Society"
  },
  {
    value: "https://www.paypal.com/US/fundraiser/charity/2283887",
    label: "National Domestic Violence Hotline"
  }
]

function Donation() {
  const [paymentLink, setPaymentLink] = useState(
    nonProfitOrganizationsStripe[0]?.value
  )

  const [platform, setPlatform] = useState("Stripe")

  const [nonProfitOrganizations, setNonProfitOrganizations] = useState(
    nonProfitOrganizationsStripe
  )

  const handlePlatformChange = e => {
    const value = e.target.value
    setPlatform(value)
    setPaymentLink(
      value === "Stripe"
        ? nonProfitOrganizationsStripe[0]?.value
        : nonProfitOrganizationsPaypal[0]?.value
    )
    setNonProfitOrganizations(
      value === "Stripe"
        ? nonProfitOrganizationsStripe
        : nonProfitOrganizationsPaypal
    )
  }

  return (
    <div style={{ padding: "0 120px" }}>
      <Flex vertical gap="32px">
        <div>
          <Flex gap="16px" style={flexContainerStyle}>
            <Flex>
              <PiggyBank />
            </Flex>

            <Flex vertical gap="8px">
              <Title level={4}>Monetary Donation</Title>
              <Text>
                Donate cash to your favourite nonprofit. You can choose how much
                to give and even donate a percentage of your purchase profits.
                Your contribution helps support important programs and
                initiatives.
              </Text>
            </Flex>
          </Flex>
        </div>

        <Flex gap="16px" vertical>
          <label style={{ color: "#000000E0" }}>Select Platform</label>
          {/* <Select
              value={platform}
              style={{ color: "#000000E0" }}
              options={[
                { value: "Stripe", label: "Stripe" },
                { value: "Paypal", label: "PayPal" }
              ]}
              onChange={value => {
                setPlatform(value)
                setPaymentLink(
                  value === "Stripe"
                    ? nonProfitOrganizationsStripe[0]?.value
                    : nonProfitOrganizationsPaypal[0]?.value
                )
                setNonProfitOrganizations(
                  value === "Stripe"
                    ? nonProfitOrganizationsStripe
                    : nonProfitOrganizationsPaypal
                )
              }}
            /> */}

          <Radio.Group
            value={platform}
            onChange={handlePlatformChange}
            style={{ display: "flex", gap: "32px" }}
            buttonStyle="outline"
            className="RadioButton"
          >
            <Radio value="Paypal">
              <PayPalIcon />
              PayPal
            </Radio>
            <Radio value="Stripe">
              <StripeIcon />
              Stripe
            </Radio>
          </Radio.Group>

          <Flex vertical gap="10px">
            <label style={{ color: "#000000E0" }}>Select Non-Profit</label>
            <Select
              value={paymentLink}
              style={{ color: "#000000E0" }}
              options={nonProfitOrganizations}
              onChange={setPaymentLink}
            />
            <Link href="https://hello.com" target="_blank" className="linkTag">
              Click here to learn more about All Good People
            </Link>
          </Flex>

          <Flex vertical gap="4px">
            <label style={{ color: "#000000E0" }}>
              Reason for donation (optional)
            </label>
            <TextArea
              rows={4}
              placeholder="Enter your top Characteristics, experience, and interests."
              maxLength={6}
            />
          </Flex>
        </Flex>

        <Flex gap="12px" justify="flex-end">
          <Button
            type="primary"
            disabled={!paymentLink}
            onClick={() => paymentLink && window.open(paymentLink, "_blank")}
          >
            Proceed to Payment
          </Button>
        </Flex>
      </Flex>

      <div>
        <DonationSuggestion />
      </div>
    </div>
  )
}

export default Donation

const flexContainerStyle = {
  backgroundColor: "rgba(250, 173, 20, 1)",
  paddingTop: "32px",
  paddingBottom: "32px",
  paddingRight: "24px",
  paddingLeft: "24px",
  borderRadius: "8px"
}

import { Col, Flex, Menu, Row } from "antd"
import Title from "antd/es/typography/Title"
import { useEffect, useState } from "react"
import tabComponents from "./tabs/tabComponents"
import CustomInput from "../../components/customInput/CustomInput"
import { SearchOutlined } from "@ant-design/icons"

import DonationSuggestion from "../donations/DonationSuggestion"
import { useLocation, useNavigate } from "react-router-dom"

const items = [
  {
    key: "account-privacy",
    label: "Account Privacy"
  },
  {
    key: "connect-social-accounts",
    label: "Connect Social Accounts"
  },
  {
    key: "consent-management",
    label: "Consent Management"
  },
  {
    key: "account-deletion",
    label: "Account Deletion"
  },
  {
    key: "feedback",
    label: "Feedback and Support"
  }
]

const Settings = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [currentTabIndex, setcurrentTabIndex] = useState("account-privacy")

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tab = searchParams.get("tab")
    if (tab) {
      setcurrentTabIndex(tab)
      searchParams.delete("tab")
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true
      }) // Update the URL without reloading the page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  console.log("currentTabIndex", currentTabIndex)
  const currentTabLabel = items.find(item => item.key === currentTabIndex).label
  const CurrentTabComponent = tabComponents[currentTabIndex]
  return (
    <Row gutter={[48, 48]}>
      <Col xl={7} xxl={5}>
        <Flex vertical gap={32}>
          <div className="">
            <Title level={2} className="mb-3" aria-label="Settings">
              Settings
            </Title>
            <CustomInput
              placeholder="Search"
              suffix={
                <SearchOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
              }
            />
          </div>
          <Menu
            style={{ width: "100%" }}
            defaultSelectedKeys={currentTabIndex}
            defaultOpenKeys={currentTabIndex}
            selectedKeys={currentTabIndex}
            mode="vertical"
            items={items}
            onSelect={event => setcurrentTabIndex(event.key)}
          />
        </Flex>
      </Col>
      <Col xl={17} xxl={19}>
        <Title level={4} aria-label={currentTabLabel} className="mb-4">
          {currentTabLabel}
        </Title>
        <CurrentTabComponent />
        
      </Col>
    </Row>
  )
}

export default Settings
